import gtmModule from '../components/analytics/gtmModule';

export const APP_TOGGLEDLEFTPANEL = 'app/TOGGLEDLEFTPANEL';
export const APP_TOGGLESEARCHOVERLAY = 'app/TOGGLESEARCHOVERLAY';
export const APP_TOGGLEMODALOVERLAY = 'app/TOGGLEMODALOVERLAY';
export const APP_TOGGLELOADED = 'app/LOADED';
export const APP_TOGGLESETTINGOVERLAY = 'app/TOGGLESETTINGOVERLAY';
export const APP_TOGGLEWATCHLISTOVERLAY = 'app/TOGGLEWATCHLISTOVERLAY';
export const APP_TOGGLEREFRESH = 'app/REFRESH';
export const APP_RESUME = 'app/RESUME';
export const APP_CONTINUE = 'app/TOGGLECONTINUE';
export const APP_LANGUAGE = 'app/LANGUAGE';
export const APP_SETTINGMODE = 'app/SETTINGMODE';
export const APP_SETTINGLANDING = 'app/SETTINGLANDING';
export const APP_FIRSTLOGIN = 'app/FIRSTLOGIN';
export const APP_HOLDACCCHECK = 'app/HOLDACCCHECK';
export const APP_SETPROMPT = 'app/SETPROMPT';
export const APP_SETMESSAGE = 'app/SETMESSAGE';
export const APP_CLEARMESSAGE = 'app/CLEARMESSAGE';
export const APP_SETDOWNLOAD = 'app/SETDOWNLOAD';
export const APP_EMBEDMODE = 'app/EMBEDMODE';
export const APP_BEFORESIGNIN = 'app/BEFORESIGNIN';
export const APP_CHECKAVIF = 'app/CHECKAVIF';


const currTimestamp = Math.round(new Date().getTime() / 1000);

if (localStorage.u) {
	const parseStorage = JSON.parse(localStorage.u);

	if (parseStorage.tokenExpiryTime) {
		if (currTimestamp > new Date(parseInt(parseStorage.tokenExpiryTime, 10)).getTime()) {
			localStorage.removeItem('u');
			// window.parent.location = `${window.parent.location.origin}/signin`;
		}
	}
}

const initialState = {
	toggledLeftPanel: false,
	searchOverlay: false,
	appLoaded: false,
	settingOverlay: false,
	modalOverlay: false,
	watchlistOverlay: false,
	continueWatchShow: true,
	appLanguage: 'en',
	appSettingMode: 'profile',
	appLanding: true,
	appFirstLogin: false,
  appRefresh: false,
	appMessage: false,
	appDownload: 0,
  appEmbedMode: false,
	appPrompt: {
		showPrompt: false,
		promptMode: null,
		jwtInfo: null,
		showHoldAccPrompt: false,
	},
  appHoldAccCheck: true,
  appEmbedResume: 0,
	appSupportLanguages: ['en','tha','idn','zh-Hant'],
  appBeforeSignIn: localStorage['before_sign_in'] || null,
  appCheckAvif: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case APP_SETDOWNLOAD:
			return {
				...state,
				appDownload: (action.payload === false)?(state.appDownload+1):action.payload
			};

		case APP_TOGGLEDLEFTPANEL:
			return {
				...state,
				toggledLeftPanel: !state.toggledLeftPanel
			};

		case APP_TOGGLESEARCHOVERLAY:
			return {
				...state,
				searchOverlay: !state.searchOverlay
			};

		case APP_TOGGLEMODALOVERLAY:
			return {
				...state,
				modalOverlay: action.payload
			};

		case APP_TOGGLELOADED:
			return {
				...state,
				appLoaded: !state.appLoaded
			};

		case APP_TOGGLESETTINGOVERLAY:
			return {
				...state,
				settingOverlay: !state.settingOverlay
			};

		case APP_TOGGLEWATCHLISTOVERLAY:
			return {
				...state,
				watchlistOverlay: !state.watchlistOverlay
			};

		case APP_TOGGLEREFRESH:
			return {
				...state,
				appRefresh: !state.appRefresh
			};

		case APP_CONTINUE:
			return {
				...state,
				continueWatchShow: action.payload
			};

		case APP_RESUME:
			return {
				...state,
				appEmbedResume: action.payload
			};

		case APP_LANGUAGE:
			return {
				...state,
				appLanguage: action.payload
			};

		case APP_SETTINGMODE:
			return {
				...state,
				appSettingMode: action.payload
			};

		case APP_SETTINGLANDING:
			return {
				...state,
				appLanding: action.payload
			};

		case APP_FIRSTLOGIN:
			return {
				...state,
				appFirstLogin: action.payload
      };

    case APP_HOLDACCCHECK:
      return {
        ...state,
        appHoldAccCheck: action.payload
      };

      case APP_SETPROMPT:
      return {
        ...state,
        appPrompt: action.payload
      };

			case APP_SETMESSAGE:
			return {
				...state,
				appMessage: action.payload
			};

			case APP_CLEARMESSAGE:
			return {
				...state,
				appMessage: false
			};

      case APP_EMBEDMODE:
      return {
        ...state,
        appEmbedMode: action.payload
      };

      case APP_BEFORESIGNIN:
      return {
        ...state,
        appBeforeSignIn: action.payload
      };

      case APP_CHECKAVIF:
      return {
        ...state,
        appCheckAvif: action.payload
      }

		default:
			return state;

	}
};

export const appToggledLeftPanel = () => {
	return dispatch => {
		dispatch({
			type: APP_TOGGLEDLEFTPANEL
		});
	};
};

export const appToggleSearchOverlay = () => {
	return dispatch => {
		dispatch({
			type: APP_TOGGLESEARCHOVERLAY
		});
	};
};

export const appToggleModalOverlay = (data) => {
	return dispatch => {
		dispatch({
			type: APP_TOGGLEMODALOVERLAY,
			payload: data,
		});
	};
};

export const appToggleLoaded = () => {
	return dispatch => {
		dispatch({
			type: APP_TOGGLELOADED
		});
	};
};

export const appToggleSettingOverlay = () => {
	return dispatch => {
		dispatch({
			type: APP_TOGGLESETTINGOVERLAY
		});
	};
};

export const appToggleWatchlistOverlay = () => {
	return dispatch => {
		dispatch({
			type: APP_TOGGLEWATCHLISTOVERLAY
		});
	};
};

export const appToggleRefresh = () => {
	return dispatch => {
		dispatch({
			type: APP_TOGGLEREFRESH,
		});
	};
};

export const appSetContinue = (data) => {
	return dispatch => {
		dispatch({
			type: APP_CONTINUE,
			payload: data,
		});
	};
};

export const appSetEmbedResume = (data) => {
	return dispatch => {
		dispatch({
			type: APP_RESUME,
			payload: data,
		});
	};
};

export const appSetEmbedMode = (data) => {
	return dispatch => {
		dispatch({
			type: APP_EMBEDMODE,
			payload: data,
		});
	};
};


export const appSetLanguage = (data) => {
	return dispatch => {
		if(data === 'zh'){
			data = 'zh-Hant';
		}
    if (localStorage.getItem('default_lang') !== data) {
      let event_category = 'HBOGO-Core';
      let event_action = 'App Language';
      let event_label = {label: data};
      gtmModule(event_category, event_action, event_label);
    }
		localStorage.setItem('default_lang', data);
		dispatch({
			type: APP_LANGUAGE,
			payload: data,
		});
	};
};

export const appSetMode = (data) => {
	return dispatch => {
		dispatch({
			type: APP_SETTINGMODE,
			payload: data,
		});
	};
};

export const appSendAnalytics = (data) => {
	return dispatch => {
		dispatch({
			type: APP_SETTINGMODE,
			payload: data,
		});
	};
};


export const appSetDownload = (data = false) => {
	return dispatch => {
		dispatch({
			type: APP_SETDOWNLOAD,
			payload: data,
		});
	};
};


export const appSetLanding = (data) => {
	return dispatch => {
		dispatch({
			type: APP_SETTINGLANDING,
			payload: data,
		});
	};
};

export const appSetFirstLogin = (data) => {
	return dispatch => {
		dispatch({
			type: APP_FIRSTLOGIN,
			payload: data,
		});
	};
};

export const appSetHoldAccCheck = (data) => {
  return dispatch => {
		dispatch({
			type: APP_HOLDACCCHECK,
			payload: data,
		});
	};
}


export const appSetPrompt = (data) => {
  return dispatch => {
		dispatch({
			type: APP_SETPROMPT,
			payload: data
		});
	};
}

export const appSetMessage = (data, warning=false, delay=false) => {
  return dispatch => {
		dispatch({
			type: APP_SETMESSAGE,
			payload: {message:data, warning, delay}
		});
	};
}

export const appSetBeforeSignIn = (data) => {
  return dispatch => {
    if (data) {
      localStorage.setItem('before_sign_in', data);
    } else {
      localStorage.removeItem('before_sign_in');
    }
		dispatch({
			type: APP_BEFORESIGNIN,
			payload: data,
		});
	};
}

export const appClearMessage = () => {
  return dispatch => {
		dispatch({
			type: APP_CLEARMESSAGE
		});
	};
}

export const appSetAvif = (data) => {
  return dispatch => {
		dispatch({
			type: APP_CHECKAVIF,
      payload: data,
		});
	};
}

export const appQuery = () => {
	let parameterObject = {};
	if(window.location.href.split('?')[1]){
		const parameters = window.location.href.split('?')[1];
		const parameterList = parameters.split('&').map(param => {
			const keyValue = param.split('=');
			const paramObject = {
				[keyValue[0]]: decodeURIComponent(keyValue[1]),
			};

			return paramObject;
		});
		parameterObject = parameterList.reduce(function(result, item) {
			var key = Object.keys(item)[0]; //first property: a, b, c
			result[key] = item[key];
			return result;
		}, {});
	}
	return parameterObject;
}

export const appEslint = () => {
	/* eslint-disable */

	// https://tc39.github.io/ecma262/#sec-array.prototype.findindex
	if (!Array.prototype.findIndex) {
		Object.defineProperty(Array.prototype, 'findIndex', {
			value: function(predicate) {
				// 1. Let O be ? ToObject(this value).
				if (this == null) {
					throw new TypeError('"this" is null or not defined');
				}

				var o = Object(this);

				// 2. Let len be ? ToLength(? Get(O, "length")).
				var len = o.length >>> 0;

				// 3. If IsCallable(predicate) is false, throw a TypeError exception.
				if (typeof predicate !== 'function') {
					throw new TypeError('predicate must be a function');
				}

				// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
				var thisArg = arguments[1];

				// 5. Let k be 0.
				var k = 0;

				// 6. Repeat, while k < len
				while (k < len) {
					// a. Let Pk be ! ToString(k).
					// b. Let kValue be ? Get(O, Pk).
					// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
					// d. If testResult is true, return k.
					var kValue = o[k];
					if (predicate.call(thisArg, kValue, k, o)) {
						return k;
					}
					// e. Increase k by 1.
					k++;
				}

				// 7. Return -1.
				return -1;
			},
			configurable: true,
			writable: true
		});
	}
	// https://tc39.github.io/ecma262/#sec-array.prototype.includes
	if (!Array.prototype.includes) {
		Object.defineProperty(Array.prototype, 'includes', {
			value: function(valueToFind, fromIndex) {

				if (this == null) {
					throw new TypeError('"this" is null or not defined');
				}

				// 1. Let O be ? ToObject(this value).
				var o = Object(this);

				// 2. Let len be ? ToLength(? Get(O, "length")).
				var len = o.length >>> 0;

				// 3. If len is 0, return false.
				if (len === 0) {
					return false;
				}

				// 4. Let n be ? ToInteger(fromIndex).
				//    (If fromIndex is undefined, this step produces the value 0.)
				var n = fromIndex | 0;

				// 5. If n ≥ 0, then
				//  a. Let k be n.
				// 6. Else n < 0,
				//  a. Let k be len + n.
				//  b. If k < 0, let k be 0.
				var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

				function sameValueZero(x, y) {
					return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
				}

				// 7. Repeat, while k < len
				while (k < len) {
					// a. Let elementK be the result of ? Get(O, ! ToString(k)).
					// b. If SameValueZero(valueToFind, elementK) is true, return true.
					if (sameValueZero(o[k], valueToFind)) {
						return true;
					}
					// c. Increase k by 1.
					k++;
				}

				// 8. Return false
				return false;
			}
		});
	}
	// Production steps of ECMA-262, Edition 6, 22.1.2.1
	if (!Array.from) {
		Array.from = (function () {
			var toStr = Object.prototype.toString;
			var isCallable = function (fn) {
				return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
			};
			var toInteger = function (value) {
				var number = Number(value);
				if (isNaN(number)) { return 0; }
				if (number === 0 || !isFinite(number)) { return number; }
				return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
			};
			var maxSafeInteger = Math.pow(2, 53) - 1;
			var toLength = function (value) {
				var len = toInteger(value);
				return Math.min(Math.max(len, 0), maxSafeInteger);
			};

			// The length property of the from method is 1.
			return function from(arrayLike/*, mapFn, thisArg */) {
				// 1. Let C be the this value.
				var C = this;

				// 2. Let items be ToObject(arrayLike).
				var items = Object(arrayLike);

				// 3. ReturnIfAbrupt(items).
				if (arrayLike == null) {
					throw new TypeError('Array.from requires an array-like object - not null or undefined');
				}

				// 4. If mapfn is undefined, then let mapping be false.
				var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
				var T;
				if (typeof mapFn !== 'undefined') {
					// 5. else
					// 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
					if (!isCallable(mapFn)) {
						throw new TypeError('Array.from: when provided, the second argument must be a function');
					}

					// 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
					if (arguments.length > 2) {
						T = arguments[2];
					}
				}

				// 10. Let lenValue be Get(items, "length").
				// 11. Let len be ToLength(lenValue).
				var len = toLength(items.length);

				// 13. If IsConstructor(C) is true, then
				// 13. a. Let A be the result of calling the [[Construct]] internal method
				// of C with an argument list containing the single item len.
				// 14. a. Else, Let A be ArrayCreate(len).
				var A = isCallable(C) ? Object(new C(len)) : new Array(len);

				// 16. Let k be 0.
				var k = 0;
				// 17. Repeat, while k < len… (also steps a - h)
				var kValue;
				while (k < len) {
					kValue = items[k];
					if (mapFn) {
						A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
					} else {
						A[k] = kValue;
					}
					k += 1;
				}
				// 18. Let putStatus be Put(A, "length", len, true).
				A.length = len;
				// 20. Return A.
				return A;
			};
		}());
	}
	/* eslint-enable */
}
