export const getQuery = (payload) => {
  if (payload) {
    if (typeof payload === 'object') {
      let temp = Object.keys(payload).map(function(key, index) {
        return `${key}=${payload[key]}`
      });
      return temp.join('&');
    } else {
      return payload;
    }
  } else {
    return '';
  }
}