import historyApi from './api/historyApi';

export const HISTORY_LIST = 'history/LIST';

const initialState = {
	historyList: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case HISTORY_LIST:
			return {
				...state,
				historyList: action.payload
			};

		default:
			return state;
		
	}
};

export const historyGetList = (country, token, sortBy, sortOrder) => {
	return dispatch => {
		return historyApi.HistoryGetList(country, token, sortBy, sortOrder).then(data => {
			dispatch({
				type: HISTORY_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const historyInsert = (payload) => {
	return dispatch => {
		return historyApi.HistoryInsert(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
