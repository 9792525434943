import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const collectionInstance = apiInstance;

class CollectionApi {
	static CollectionGetContent(country, page, query = '') {
		return collectionInstance.get(`/collection/${page}?territory=${country}&platform=web${query}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static CollectionGetLayout(country, page, query = '') {
		return collectionInstance.get(`/collection/${page}?territory=${country}&type=layout&platform=web${query}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default CollectionApi;
