import seriesApi from './api/seriesApi';

export const SERIES_CONTENT = 'series/CONTENT';
export const SERIES_SPECIFIC = 'series/SPECIFIC';
export const SERIES_SEASON_LIST = 'series/SEASONLIST';
export const SERIES_EPISODE_LIST = 'series/EPISODELIST';
export const SERIES_EPISODE = 'series/EPISODE';

const initialState = {
	seriesContent: null,
	seriesSpecific: null,
	seriesSeasonList: null,
	seriesEpisodeList: null,
	seriesEpisode: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case SERIES_CONTENT:
			return {
				...state,
				seriesContent: action.payload
			};

		case SERIES_SPECIFIC:
			return {
				...state,
				seriesSpecific: action.payload
			};

		case SERIES_SEASON_LIST:
			return {
				...state,
				seriesSeasonList: action.payload
			};

		case SERIES_EPISODE_LIST:
			return {
				...state,
				seriesEpisodeList: action.payload
			};

		case SERIES_EPISODE:
			return {
				...state,
				seriesEpisode: action.payload
			};

		default:
			return state;

	}
};

export const seriesGetContent = (country) => {
	return dispatch => {
		return seriesApi.SeriesGetContent(country).then(data => {
      dispatch({
        type: SERIES_CONTENT,
        payload: data,
      });				
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const seriesGetSomeContent = (country, genre, page, perPage) => {
	return dispatch => {
		return seriesApi.SeriesGetSomeContent(country, genre, page, perPage).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const seriesGetSpecific = (country, id, header = false) => {
	return dispatch => {
		return seriesApi.SeriesGetSpecific(country, id, header).then(data => {
			dispatch({
				type: SERIES_SPECIFIC,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const seriesGetSeasonList = (country, id) => {
	return dispatch => {
		return seriesApi.SeriesGetSeasonList(country, id).then(data => {
			dispatch({
				type: SERIES_SEASON_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const SeriesGetSeason = (country, id) => {
	return dispatch => {
		return seriesApi.SeriesGetSeason(country, id).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const seriesGetEpisodeList = (country, id) => {
	return dispatch => {
		return seriesApi.SeriesGetEpisodeList(country, id).then(data => {
			dispatch({
				type: SERIES_EPISODE_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const seriesGetEpisode = (country, id) => {
	return dispatch => {
		return seriesApi.SeriesGetEpisode(country, id).then(data => {
			dispatch({
				type: SERIES_EPISODE,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
