import thematicApi from './api/thematicApi';

export const THEMATIC_LIST = 'thematic/LIST';

const initialState = {
	thematicList: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case THEMATIC_LIST:
			return {
				...state,
				thematicList: action.payload
			};

		default:
			return state;

	}
};

export const thematicGetList = () => {
	return dispatch => {
		return thematicApi.ThematicGetList().then(data => {
			dispatch({
				type: THEMATIC_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
