import landingApi from './api/landingApi';

export const LANDING_CONTENT = 'landing/CONTENT';

const initialState = {
	landingContent: {},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case LANDING_CONTENT:
			return {
				...state,
				landingContent: action.payload
			};

		default:
			return state;

	}
};

export const landingGetContent = (country) => {
	return dispatch => {
		return landingApi.LandingGetContent(country).then(res => {
      let data = res && Object.keys(res).length ? res : null;
			dispatch({
				type: LANDING_CONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			console.error(error);
      dispatch({
				type: LANDING_CONTENT,
				payload: null,
			});
      return null;
		});
	};
};
