import liveApi from './api/liveApi';

export const live_LIST = 'live/LIST';
export const live_CHANNEL = 'live/CHANNEL';
export const live_CONCURRENT = 'live/CONCURRENT';
export const live_PLAYBACK_URLLIST = 'live/URLLIST';

const initialState = {
	liveList: null,
	liveChannel: null,
	liveConcurrent: null,
	livePlaybackURLList: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case live_LIST:
			return {
				...state,
				liveList: action.payload
			};

		case live_CHANNEL:
			return {
				...state,
				liveChannel: action.payload
			};

		case live_CONCURRENT:
			return {
				...state,
				liveConcurrent: action.payload
			};

		case live_PLAYBACK_URLLIST:
			return {
				...state,
				livePlaybackURLList: action.payload
			};

		default:
			return state;

	}
};

export const liveGetList = (country) => {
	return dispatch => {
		return liveApi.LiveGetList(country).then(data => {
			dispatch({
				type: live_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const liveGetChannel = (country, channel) => {
	return dispatch => {
		return liveApi.LiveGetChannel(country, channel).then(data => {
			dispatch({
				type: live_CHANNEL,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const liveCheckConcurrent = (country, channel, token, operator) => {
	return dispatch => {
		return liveApi.LiveCheckConcurrent(country, channel, token, operator).then(data => {
			dispatch({
				type: live_CONCURRENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};


export const livePlaybackGetURLList = (country, id, token, operatorId) => {
	return dispatch => {
		return liveApi.LivePlaybackGetURLList(country, id, token, operatorId).then(data => {
			dispatch({
				type: live_PLAYBACK_URLLIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
