import searchApi from './api/searchApi';

export const SEARCH_GENRE = 'search/GENRE';
export const SEARCH_CONTENT = 'search/CONTENT';
export const SEARCH_RESULTS = 'search/RESULTS';
export const SEARCH_CLEAR = 'search/CLEAR';
export const SEARCH_FREECONTENT = 'search/FREECONTENT';

const initialState = {
	searchGenreResult: null,
	searchContent: null,
	searchResults: null,
	searchFreeContent: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case SEARCH_GENRE:
			return {
				...state,
				searchGenreResult: action.payload
			};

		case SEARCH_CONTENT:
			return {
				...state,
				searchContent: action.payload
			};

		case SEARCH_FREECONTENT:
			return {
				...state,
				searchFreeContent: action.payload
			};

		case SEARCH_RESULTS:
			if (!state.searchResults || (state.searchResults && state.searchResults.queryTimestamp < action.payload.queryTimestamp)) {
				return {
					...state,
					searchResults: action.payload
				};
			}

			return {
				...state,
				searchResults: state.searchResults
			};

		case SEARCH_CLEAR:
			return {
				...state,
				searchResults: null
			};

		default:
			return state;
		
	}
};

export const searchGetGenre = (country, genre, sortBy, sortOrder) => {
	return dispatch => {
		return searchApi.SearchGetGenre(country, genre, sortBy, sortOrder).then(data => {
			dispatch({
				type: SEARCH_GENRE,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const searchGetMovieSeries = (country, genre, sortBy, sortOrder, sortType) => {
	return dispatch => {
		return searchApi.SearchGetMovieSeries(country, genre, sortBy, sortOrder, sortType).then(data => {
			dispatch({
				type: SEARCH_GENRE,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const searchGetContent = (country, id) => {
	return dispatch => {
		return searchApi.SearchGetContent(country, id).then(data => {
			dispatch({
				type: SEARCH_CONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const searchGetFreeContent = (country, sortBy, sortOrder) => {
	return dispatch => {
		return searchApi.SearchGetFreeContent(country, sortBy, sortOrder).then(data => {
			dispatch({
				type: SEARCH_FREECONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const searchGetResults = (country, title) => {
	return dispatch => {
		return searchApi.SearchGetResults(country, title).then(data => {
			dispatch({
				type: SEARCH_RESULTS,
				payload: data,
			});

			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const searchClearResults = (country, title) => {
	return dispatch => {
		dispatch({
			type: SEARCH_CLEAR,
		});
	};
};
