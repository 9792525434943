import parentalApi from './api/parentalApi';

export const PARENTAL_ITEM = 'parental/ITEM';
export const PARENTAL_VALIDATE = 'parental/VALIDATE';
export const PARENTAL_VERIFY = 'parental/VERIFY';
export const PARENTAL_VERIFYCURRENT = 'parental/VERIFYCURRENT';

const initialState = {
	parentalItem: null,
	parentalResponse: null,
	parentalVerification: null,
	parentalVerificationCurrent: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PARENTAL_ITEM: 
			return {
				...state,
				parentalItem: action.payload
			};

		case PARENTAL_VALIDATE:
			return {
				...state,
				parentalResponse: action.payload
			};

		case PARENTAL_VERIFY:
			return {
				...state,
				parentalVerification: action.payload
			};

		case PARENTAL_VERIFYCURRENT:
			return {
				...state,
				parentalVerificationCurrent: action.payload
			};

		default:
			return state;
		
	}
};

export const parentalGet = (token, territory) => {
	return dispatch => {
		return parentalApi.ParentalGet(token, territory).then(data => {
			dispatch({
				type: PARENTAL_ITEM,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const parentalValidate = (payload) => {
	return dispatch => {
		return parentalApi.ParentalValidate(payload).then(data => {
			dispatch({
				type: PARENTAL_VALIDATE,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const parentalVerify = (payload) => {
	return dispatch => {
		return parentalApi.ParentalVerify(payload).then(data => {
			dispatch({
				type: PARENTAL_VERIFY,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const parentalVerifyCurrent = (payload) => {
	return dispatch => {
		return parentalApi.ParentalVerifyCurrent(payload).then(data => {
			dispatch({
				type: PARENTAL_VERIFYCURRENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const parentalUpdateLevel = (payload) => {
	return dispatch => {
		return parentalApi.ParentalUpdateLevel(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const parentalUpdatePIN = (payload) => {
	return dispatch => {
		return parentalApi.ParentalUpdatePIN(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const parentalResetPINEmail = (payload) => {
	return dispatch => {
		return parentalApi.ParentalResetPINEmail(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const parentalUpdate = (payload) => {
  return dispatch => {
		return parentalApi.ParentalUpdate(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
}