import promotionApi from './api/promotionApi';

export const PROMOTION_SPECIFIC = 'promotion/SPECIFIC';
export const PROMOTION_CLEAR = 'promotion/CLEAR';
export const PROMOTION_MASTERCARD = 'promotion/MASTERCARD';
export const PROMOTION_MASTERCARD_CLEAR = 'promotion/MASTERCARD_CLEAR'

const initialState = {
  promotionSpecific: null,
  masterCardPromotion: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PROMOTION_SPECIFIC:
			return {
				...state,
				promotionSpecific: action.payload
			};
    case PROMOTION_MASTERCARD:
      return {
				...state,
				masterCardPromotion: action.payload
			};
		case PROMOTION_CLEAR:
			return {
				...state,
				promotionSpecific: null
			};
    case PROMOTION_MASTERCARD_CLEAR:
      return {
        ...state,
        masterCardPromotion: null
      };
		default:
			return state;
	}
};

export const promotionGetSpecific = (channelPartnerID) => {
  return dispatch => {
		return promotionApi.PromotionGet(channelPartnerID).then(data => {
			dispatch({
				type: PROMOTION_SPECIFIC,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
}

export const promotionSelectPromo = (data) => {
  return dispatch => {
    dispatch({
      type: PROMOTION_MASTERCARD,
      payload: data,
    });
	};
}

export const promotionLeave = () => {
  return dispatch => {
		dispatch({
			type: PROMOTION_CLEAR
		});
	};
}

export const promotionCancelPromo = () => {
  return dispatch => {
		dispatch({
			type: PROMOTION_MASTERCARD_CLEAR
		});
	};
}
