import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const micrositeInstance = apiInstance;

class MicrositeApi {
	static MicrositeGet(path, previewCode, country) {
		return micrositeInstance.get(`/microsite?lang=${localStorage.default_lang}&path=${path}&previewCode=${previewCode}&territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static IsValidCoupon(payload) {
    return micrositeInstance.post(`/microsite/isValidCoupon?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			return error;
		});
  }

  static RedeemCoupon(payload) {
    return micrositeInstance.post(`/microsite/redeemCoupon?lang=${localStorage.default_lang}`, payload)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
  }
}

export default MicrositeApi;
