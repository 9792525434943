import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const epgInstance = apiInstance;

class EPGApi {
	static EPGGetContent(country, channel) {
		return epgInstance.get(`/epg/now?territory=${country}&channel=${channel}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default EPGApi;
