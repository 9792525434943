import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const promoInstance = apiInstance;

class PromoApi {
	static PromoGetParentList(country, id) {
		return promoInstance.get(`/promo/list?territory=${country}&parentId=${id}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static PromoGetList(country, sortBy, sortOrder) {
		return promoInstance.get(`/promo/list?territory=${country}&sortBy=${sortBy}&sortOrder=${sortOrder}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static PromoGetSpecific(country, id) {
		return promoInstance.get(`/promo?territory=${country}&contentId=${id}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default PromoApi;
