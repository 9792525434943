import dolbyApi from './api/dolbyApi';

export const DOLBY_CHECK = 'dolby/CHECK';

const initialState = {
	supportDolby: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case DOLBY_CHECK:
			return {
				...state,
				staticPage: action.payload
			};

		default:
			return state;
		
	}
};

export const dolbyCheck = () => {
	return dispatch => {
		return dolbyApi.DolbyCheck().then(data => {
			dispatch({
				type: DOLBY_CHECK,
				payload: data.supportDolby,
			});
			return data.supportDolby;
		}).catch(error => {
			throw(error);
		});
	};
};
