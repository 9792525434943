import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const thematicInstance = apiInstance;

class ThematicApi {
	static ThematicGetList() {
		return thematicInstance.get(`/thematicrail`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default ThematicApi;
