import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const staticInstance = apiInstance;

class StaticApi {
	static StaticGetPage(country, id, lang, prefix) {
		return staticInstance.get(`/static?territory=${country}&contentId=${id}&lang=${lang}${prefix}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default StaticApi;
