import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const overlayInstance = apiInstance;

class OverlayApi {
	static OverlayGetContent(country) {
		// let deviceType = (window.location.pathname === '/')?'mobile':'desktop';
		let deviceType = 'mobile';
		return overlayInstance.get(`/overlay?territory=${country}&lang=${localStorage.default_lang}&deviceType=${deviceType}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default OverlayApi;
