import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const partnerInstance = apiInstance;

class PartnerApi {
	static PartnerGet(path, previewCode, country) {
		return partnerInstance.get(`/partner?lang=${localStorage.default_lang}&path=${path}&previewCode=${previewCode}&territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static PartnerGetCountry(country) {
		return partnerInstance.get(`/partner?territory=${country}&showAll=true`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }
}

export default PartnerApi;
