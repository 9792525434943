import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const ratingInstance = apiInstance;

class RatingApi {
	static RatingGetList(token, id) {
		return ratingInstance.get(`/hbouser/rating?sessionToken=${token}&channelPartnerID=${localStorage.channel_partner_id}&multiProfileId=0&contentId=${id}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static RatingGetListWithoutToken(id) {
		return ratingInstance.get(`/hbouser/rating?contentId=${id}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static RatingInsert(payload) {
		return ratingInstance.post(`/hbouser/rating`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default RatingApi;
