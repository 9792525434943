import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const voucherInstance = apiInstance;

class VoucherApi {
	static VoucherCheck(payload) {
		return voucherInstance.post(`/tvapp/registerDevice?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default VoucherApi;
