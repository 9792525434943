import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import app from './app';
import user from './user';
import video from './video';
import layout from './layout';
import collection from './collection';
import movie from './movie';
import promo from './promo';
import series from './series';
import search from './search';
import epg from './epg';
import overlay from './overlay';
import live from './live';
import playback from './playback';
import setting from './setting';
import watchlist from './watchlist';
import continuewatch from './continuewatch';
import history from './history';
import systempage from './systempage';
import parental from './parental';
import rating from './rating';
import language from './language';
import translation from './translation';
import voucher from './voucher';
import microsite from './microsite';
import partner from './partner';
import promotion from './promotion';
import dolby from './dolby';
import sysinfo from './sysinfo';
import thematic from './thematic';
import landing from './landing';
import impression from './impression';
import recommendation from './recommendation';

export default combineReducers({
	router: routerReducer,
	app,
	user,
	video,
	layout,
	collection,
	movie,
	promo,
	series,
	search,
	epg,
	overlay,
	live,
	playback,
	setting,
	watchlist,
	continuewatch,
	history,
	systempage,
	parental,
	rating,
	language,
	translation,
  voucher,
  microsite,
	partner,
  promotion,
  dolby,
  sysinfo,
  thematic,
  landing,
  impression,
  recommendation,
});
