import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const landingInstance = apiInstance;

class LandingApi {
	static LandingGetContent(country) {
		return landingInstance.get(`/landing?territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default LandingApi;
