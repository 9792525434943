import promoApi from './api/promoApi';

export const PROMO_PARENT_LIST = 'promo/PARENTLIST';
export const PROMO_SPECIFIC = 'promo/SPECIFIC';
export const PROMO_LIST = 'promo/LIST';

const initialState = {
	promoParentList: null,
	promoSpecific: null,
	promoList: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PROMO_PARENT_LIST:
			return {
				...state,
				promoParentList: action.payload
			};

		case PROMO_SPECIFIC:
			return {
				...state,
				promoSpecific: action.payload
			};

		case PROMO_LIST:
			return {
				...state,
				promoList: action.payload
			};

		default:
			return state;
		
	}
};

export const promoGetParentList = (country, id) => {
	return dispatch => {
		return promoApi.PromoGetParentList(country, id).then(data => {
			dispatch({
				type: PROMO_PARENT_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const promoGetList = (country, sortBy, sortOrder) => {
	return dispatch => {
		return promoApi.PromoGetList(country, sortBy, sortOrder).then(data => {
			dispatch({
				type: PROMO_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const promoGetSpecific = (country, id) => {
	return dispatch => {
		return promoApi.PromoGetSpecific(country, id).then(data => {
			dispatch({
				type: PROMO_SPECIFIC,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
