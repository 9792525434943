import ReactGA from 'react-ga';
import config from "../../config";

// const oneTrustGAType = 'ad';
// const gaEnable = (window.OnetrustActiveGroups)?(window.OnetrustActiveGroups.indexOf(oneTrustGAType) > -1)?true:false:false;

const gaEnable = true;

class gaModule {


  static event(payload) {
    if(gaEnable){
      ReactGA.event(payload);
    }
  };

  static pageview(payload) {
    if(gaEnable){
      ReactGA.pageview(payload);
    }
  };

  static timing(payload) {
    if(gaEnable){
      ReactGA.timing(payload);
    }
  };
};

export default gaModule;
