import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const continuewatchInstance = apiInstance;

class ContinuewatchApi {
	static ContinuewatchGetList(country, token, sortBy, sortOrder) {
		let sortByUrl = '';
		let sortOrderUrl = '';

		if (sortBy) {
			sortByUrl = `&sortBy=${sortBy}`;
		}

		if (sortOrder) {
			sortOrderUrl = `&sortOrder=${sortOrder}`;
		}

		return continuewatchInstance.get(`/continuewatch?territory=${country}&sessionToken=${token}&channelPartnerID=${localStorage.channel_partner_id}&multiProfileId=0${sortByUrl}${sortOrderUrl}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static ContinuewatchDeleteAll(payload) {
		return continuewatchInstance.post('/continuewatch/removeAll', payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static ContinuewatchInsert(payload) {
		return continuewatchInstance.post('/continuewatch', payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static ContinuewatchSetunreliable(payload) {
		return continuewatchInstance.post('/continuewatch/setunreliable', {...payload,isUnreliable:true})
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static ContinuewatchRemove(payload) {
		return continuewatchInstance.post('/continuewatch/hide', payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

}

export default ContinuewatchApi;
