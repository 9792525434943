let config = {
	projectname: 'HBO',
	api: `${process.env.REACT_APP_API}`,
	version: `${process.env.REACT_APP_BAMBOO_BUILD_NUMBER}`,
	elastic: `${process.env.REACT_APP_ELASTIC}`,
	conviva: `${process.env.REACT_APP_CONVIVA}`,
	evergent: `${process.env.REACT_APP_EVERGENT}`,
	convivaEnv: `${process.env.REACT_APP_CONVIVA_ENV}`,
	evUnify: `${process.env.REACT_APP_EVERGENT_UNIFY}`,
	unifyCountries: `${process.env.REACT_APP_UNIFY_COUNTRIES}`,
	iapCountries: `${process.env.REACT_APP_IAP_COUNTRIES}`,
	tvCountries: `${process.env.REACT_APP_TV_COUNTRIES}`,
  creditCardCountries: process.env.REACT_APP_CC_COUNTRIES || 'SGP,MYS,TWN,THA,PHL',
	creditCardSkipCountryCheck: process.env.REACT_APP_CC_SKIP_COUNTRY_CHECK || 'true',
	releasedCountries: `${process.env.REACT_APP_RELEASED_COUNTRIES}`,
	landing: `${process.env.REACT_APP_LANDING}`,
	freepreview: (`${process.env.REACT_APP_FREEPREVIEW}` === 'true')?true:false,
	gtmFlag: (`${process.env.REACT_APP_GTM}` === 'true')?true:false,
  sgFlow:(`${process.env.REACT_APP_SGFLOW}` === 'true')?true:false,
  micrositeFlag:(`${process.env.REACT_APP_MICROSITE}` === 'true')?true:false,
	brazeApiKey: `${process.env.REACT_APP_BRAZE_API_KEY}`,
	adyenEnv: `${process.env.REACT_APP_ADYEN_ENV}`,
	adyenClientKey: `${process.env.REACT_APP_ADYEN_CLIENT_KEY}`,
  enablePreviewPartnerSite: (`${process.env.REACT_APP_PREVIEWPARTNERSITE}` === 'true')?true:false,
  adyenTranslationTH: process.env.REACT_APP_ADYEN_TRANSLATION_TH || 'https://dai3fd1oh325y.cloudfront.net/images/apiupload/etc/adyen.th-TH.json',
  safariPushId: process.env.REACT_APP_SAFARI_PUSH_ID || 'web.sgweb-hbostag2.onwardsmg',
  scheduleCountries: process.env.REACT_APP_SCHEDULE_COUNTRIES || 'TWN',
  showMoviesSeriesRail: process.env.REACT_APP_SHOW_MOVIES_SERIES_RAIL === 'true' ? true : false,
  enableReCaptcha: process.env.REACT_APP_RECAPTCHA === 'true' ? true : false,
  muxEnvKey: `${process.env.REACT_APP_MUX_ENV_KEY}`,
  googleOptimizeId: process.env.REACT_APP_GOOGLE_OPTIMIZE_ID || 'OPT-WXW6DZ2',
  googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || 'G-YMH8EPCS5S',
  appsflyerKey: process.env.REACT_APP_APPSFLYER_KEY,
  siteKey: '6Lf7EUkgAAAAADq-F3Hjz3zAo2NhZAMKXAed2BJ3',
	ga: 'UA-40154784-18',
	pixel: {
		dev: '938247166542507',
		uat: '938247166542507',
		prod: '172333263475461'
	},
	gtm: {
		dev: 'GTM-TVTMHW3',
		uat: 'GTM-TVTMHW3',
		prod: 'GTM-TVTMHW3'
	},
	appLink: {
		apple: {
			sgp: 'https://itunes.apple.com/us/app/hbo-go-singapore/id1441047916?ls=1&mt=8',
			mys: 'https://itunes.apple.com/us/app/hbo-go-singapore/id1441047916?ls=1&mt=8',
			idn: 'https://itunes.apple.com/us/app/hbo-go-singapore/id1441047916?ls=1&mt=8',
			phl: 'https://itunes.apple.com/us/app/hbo-go-singapore/id1441047916?ls=1&mt=8',
			hkg: 'https://itunes.apple.com/hk/app/hbo-go-hong-kong/id594482752?l=en&mt=8',
			twn: 'https://itunes.apple.com/us/app/hbo-go-singapore/id1441047916?ls=1&mt=8',
			vip: 'itms-services://?action=download-manifest&url=itms-services://?action=download-manifest&url=https%3A%2F%2Fdai3fd1oh325y.cloudfront.net%2Fimages%2Fhbogoasia.vip.plist',
		},
		google: {
			sgp: 'https://play.google.com/store/apps/details?id=sg.hbo.hbogo',
			mys: 'https://play.google.com/store/apps/details?id=sg.hbo.hbogo',
			idn: 'https://play.google.com/store/apps/details?id=sg.hbo.hbogo',
			phl: 'https://play.google.com/store/apps/details?id=sg.hbo.hbogo',
			hkg: 'https://play.google.com/store/apps/details?id=hk.hbo.hbogo',
			twn: 'https://play.google.com/store/apps/details?id=sg.hbo.hbogo',
			vip: 'https://dai3fd1oh325y.cloudfront.net/images/hbogoasia.vip.apk',
		}
	},
	languages: [ //configure language to ./translate/index.js
		{ name: "English", code: "en" },
		{ name: "Bahasa Melayu", code: "mys" },
		{ name: "简体中文", code: "zh-Hans" },
		{ name: "繁體中文", code: "zh-Hant" },
		{ name: "Bahasa Indonesia", code: "idn" },
		{ name: "ภาษาไทย", code: "tha" }
	]
};

export default config;
