import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const parentalInstance = apiInstance;

class ParentalApi {
	static ParentalGet(token, territory) {
		var payload = {
			sessionToken: token,
			channelPartnerID: localStorage.channel_partner_id,
			territory: territory
		}
		return parentalInstance.post(`/hbouser/parentalcontrol?lang=${localStorage.default_lang}`, payload)
		// return parentalInstance.get(`/hbouser/parentalcontrol?sessionToken=${token}&channelPartnerID=${localStorage.channel_partner_id}&territory=${territory}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static ParentalValidate(payload) {
		return parentalInstance.post("/hbouser/parentalcontrol/validate", payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static ParentalVerify(payload) {
		return parentalInstance.post("/hbouser/parentalcontrol/verifyRating", payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static ParentalVerifyCurrent(payload) {
		return parentalInstance.post("/hbouser/parentalcontrol/verifyCurrentRating", payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

  static ParentalUpdateLevel(payload) {
    return parentalInstance.post("/hbouser/changeParentalControlLevel", payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static ParentalUpdatePIN(payload) {
    return parentalInstance.post("/hbouser/changeParentalControlPin", payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static ParentalResetPINEmail(payload) {
    return parentalInstance.post("/hbouser/sendresetpinmail", payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static ParentalUpdate(payload) {
    return parentalInstance.patch("/hbouser/parentalcontrol", payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }
}

export default ParentalApi;
