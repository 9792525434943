import watchlistApi from './api/watchlistApi';

export const WATCHLIST_LIST = 'watchlist/LIST';

const initialState = {
	watchlistList: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case WATCHLIST_LIST:
			return {
				...state,
				watchlistList: action.payload
			};

		default:
			return state;

	}
};

export const watchlistGetList = (country, token, sortBy, sortOrder) => {
	return dispatch => {
		return watchlistApi.WatchlistGetList(country, token, sortBy, sortOrder).then(data => {
			dispatch({
				type: WATCHLIST_LIST,
				payload: data,
			});

			let watchlistData = [];

			if (data.results.length > 0) {
				watchlistData = data.results.map(watch => {
					return watch.contentId;
				});
			}

			localStorage.setItem('watchlist', JSON.stringify(watchlistData));

			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const watchlistDelete = (id, payload) => {
	return dispatch => {
		return watchlistApi.WatchlistDelete(id, payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const watchlistDeleteAll = (payload) => {
	return dispatch => {
		return watchlistApi.WatchlistDeleteAll(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const watchlistDeleteMultiple = (payload) => {
	return dispatch => {
		return watchlistApi.WatchlistDeleteMultiple(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const watchlistInsert = (payload) => {
	return dispatch => {
		return watchlistApi.WatchlistInsert(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
