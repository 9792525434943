import axios from 'axios';
import config from '../../config';


const apiInstance = axios.create({
	baseURL: config.api,
  headers: (localStorage.environment)?{
    'Content-Type': 'application/json',
		'x-hbogo-env': localStorage.environment
  }:{
    'Content-Type': 'application/json'
  },
});

export default apiInstance;
