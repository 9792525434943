import app from './app.json';
import toppanel from './toppanel.json';
import footer from './footer.json';
import player from './player.json';
import content from './content.json';
import series from './series.json';
import iframe from './iframe.json';
import setting from './setting.json';
import watchlist from './watchlist.json';
import search from './search.json';
import order from './order.json';
import device from './device.json';

let translate = {
	app,
	toppanel,
	footer,
	player,
	content,
	series,
	iframe,
	setting,
	watchlist,
	search,
	order,
	device,
};

export default translate;
