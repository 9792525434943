import videoApi from './api/videoApi';

export const VIDEO_SPECIFIC = 'video/SPECIFIC';
export const VIDEO_EXTRA = 'video/EXTRA';
export const TRENDING_LIST = 'video/TRENDINGLIST';
export const MOVIE_LIST = 'video/MOVIELIST';
export const DOCUMENTARY_LIST = 'video/DOCUMENTARYLIST';
export const FAMILY_LIST = 'video/FAMILYLIST';
export const SPECIAL_LIST = 'video/SPECIALLIST';
export const COLLECTION_LIST = 'video/COLLECTIONLIST';
export const WITHOUTREGISTER_LIST = 'video/WITHOUTREGISTERLIST';
export const CAROUSEL_LIST = 'video/CAROUSELLIST';

const initialState = {
	videoSpecific: null,
	videoTrendingList: null,
	videoMovieList: null,
	videoDocumentaryList: null,
	videoFamilyList: null,
	videoSpecialList: null,
	videoCollectionList: null,
	videoWithoutRegisterList: null,
	videoCarouselList: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case VIDEO_SPECIFIC:
			return {
				...state,
				videoSpecific: action.payload,
			};

		case VIDEO_EXTRA:
			return {
				...state,
				videoExtra: action.payload,
			};

		case TRENDING_LIST:
			return {
				...state,
				videoTrendingList: action.payload,
			};

		case MOVIE_LIST:
			return {
				...state,
				videoMovieList: action.payload,
			};

		case DOCUMENTARY_LIST:
			return {
				...state,
				videoDocumentaryList: action.payload,
			};

		case FAMILY_LIST:
			return {
				...state,
				videoFamilyList: action.payload,
			};

		case SPECIAL_LIST:
			return {
				...state,
				videoSpecialList: action.payload,
			};

		case COLLECTION_LIST:
			return {
				...state,
				videoCollectionList: action.payload,
			};

		case WITHOUTREGISTER_LIST:
			return {
				...state,
				videoWithoutRegisterList: action.payload,
			};

		case CAROUSEL_LIST:
			return {
				...state,
				videoCarouselList: action.payload,
			};

		default:
			return state;
		
	}
};

export const videoGetSpecific = (id) => {
	return dispatch => {
		return videoApi.VideoGetSpecific(id).then(data => {
			dispatch({
				type: VIDEO_SPECIFIC,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const videoGetExtra = (id) => {
	return dispatch => {
		return videoApi.VideoGetExtra(id).then(data => {
			dispatch({
				type: VIDEO_EXTRA,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const videoGetListTrending = () => {
	return dispatch => {
		return videoApi.VideoGetListTrending('TRENDING+NOW').then(data => {
			dispatch({
				type: TRENDING_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const videoGetListMovie = () => {
	return dispatch => {
		return videoApi.VideoGetList('Movies').then(data => {
			dispatch({
				type: MOVIE_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const videoGetListDocumentary = () => {
	return dispatch => {
		return videoApi.VideoGetList('Documentaries').then(data => {
			dispatch({
				type: DOCUMENTARY_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const videoGetListFamily = () => {
	return dispatch => {
		return videoApi.VideoGetList('Kids+%26+Family').then(data => {
			dispatch({
				type: FAMILY_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const videoGetListSpecial = () => {
	return dispatch => {
		return videoApi.VideoGetList('Specials').then(data => {
			dispatch({
				type: SPECIAL_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const videoGetListCollection = () => {
	return dispatch => {
		return videoApi.VideoGetList('Collections').then(data => {
			dispatch({
				type: COLLECTION_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const videoGetListWithoutRegister = () => {
	return dispatch => {
		return videoApi.VideoGetList('Without+Registration').then(data => {
			dispatch({
				type: WITHOUTREGISTER_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const videoGetListCarousel = () => {
	return dispatch => {
		return videoApi.VideoGetListTrending('CAROUSEL').then(data => {
			dispatch({
				type: CAROUSEL_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
