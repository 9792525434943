import languageApi from './api/languageApi';

export const LANGUAGE_DEFAULTLIST = 'language/DEFAULTLIST';
export const LANGUAGE_AUDIOLIST = 'language/AUDIOLIST';
export const LANGUAGE_SUBTITLELIST = 'language/SUBTITLELIST';

const initialState = {
	languageAudioList: null,
	languageSubtitleList: null,
	languageDefaultList: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LANGUAGE_DEFAULTLIST:
			return {
				...state,
				languageDefaultList: action.payload
			};

		case LANGUAGE_AUDIOLIST:
			return {
				...state,
				languageAudioList: action.payload
			};

		case LANGUAGE_SUBTITLELIST:
			return {
				...state,
				languageSubtitleList: action.payload
			};

		default:
			return state;

	}
};


export const languageGetDefault = () => {
	return dispatch => {
		return languageApi.LanguageGetList('?type=default').then(data => {
			dispatch({
				type: LANGUAGE_DEFAULTLIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};


export const languageGetAudio = (territory) => {
	return dispatch => {
		return languageApi.LanguageGetList('audioLanguageMap?territory='+territory).then(data => {
			dispatch({
				type: LANGUAGE_AUDIOLIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const languageGetSubtitle = (territory) => {
	return dispatch => {
		return languageApi.LanguageGetList('subtitleLanguageMap?territory='+territory).then(data => {
			dispatch({
				type: LANGUAGE_SUBTITLELIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
