import epgApi from './api/epgApi';

export const EPG_CONTENT = 'epg/CONTENT';

const initialState = {
	epgContent: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case EPG_CONTENT:
			return {
				...state,
				epgContent: action.payload
			};

		default:
			return state;
		
	}
};

export const epgGetContent = (country, channel) => {
	return dispatch => {
		return epgApi.EPGGetContent(country, channel).then(data => {
			dispatch({
				type: EPG_CONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
