import settingApi from './api/settingApi';

export const SETTING_PROFILE = 'setting/PROFILE';

const initialState = {
	settingProfile: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case SETTING_PROFILE:
			return {
				...state,
				settingProfile: action.payload
			};

		default:
			return state;
		
	}
};

export const settingGetProfile = (payload) => {
	return dispatch => {
		return settingApi.SettingGetProfile(payload).then(data => {
			dispatch({
				type: SETTING_PROFILE,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
