import systempageApi from './api/systempageApi';

export const STATIC_PAGE = 'static/PAGE';

const initialState = {
	staticPage: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case STATIC_PAGE:
			return {
				...state,
				staticPage: action.payload
			};

		default:
			return state;
		
	}
};

export const staticGetPage = (country, id, lang, prefix) => {
	return dispatch => {
		return systempageApi.StaticGetPage(country, id, lang, prefix).then(data => {
			dispatch({
				type: STATIC_PAGE,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
