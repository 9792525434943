import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const promotionInstance = apiInstance;

class PromotionApi {
	static PromotionGet(channelPartnerID) {
		return promotionInstance.get(`/promotion?channelPartnerID=${channelPartnerID}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default PromotionApi;
