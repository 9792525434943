import overlayApi from './api/overlayApi';

export const OVERLAY_CONTENT = 'overlay/CONTENT';

const initialState = {
	overlayContent: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case OVERLAY_CONTENT:
			return {
				...state,
				overlayContent: action.payload
			};

		default:
			return state;
		
	}
};

export const overlayGetContent = (country) => {
	return dispatch => {
		return overlayApi.OverlayGetContent(country).then(data => {
			dispatch({
				type: OVERLAY_CONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
