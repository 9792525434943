// import appboy from "@braze/web-sdk";
import userApi from './api/userApi';
import parentalApi from './api/parentalApi';
import config from "../config";
import gtmModule from '../components/analytics/gtmModule';

export const USER_SETAUTHENTICATION = 'user/SETAUTHENTICATION';
export const USER_LOGIN = 'user/LOGIN';
export const USER_LOGOUT = 'user/LOGOUT';
export const USER_FORGOTPASSWORD = 'user/FORGOTPASSWORD';
export const USER_RESETPASSWORD = 'user/RESETPASSWORD';
export const USER_SESSION = 'user/SESSION';
export const USER_COUNTRY = 'user/COUNTRY';
export const USER_VIP = 'user/VIP';
export const USER_PREVIOUS_URL = 'user/PREVIOUSURL';
export const USER_PROFILE = 'user/PROFILE';
export const USER_DEVICE_INFO = 'user/DEVICEINFO';
export const USER_OUTSIDEREGION = 'user/OUTSIDEREGION';
export const USER_TV = 'user/TV';
export const USER_VOUCHER_JWT = 'user/VOUCHERJWT';
export const USER_SEARCH_ACCOUNT = 'user/SEARCHACCOUNT';
export const USER_OPERATOR_JWT = 'user/OPERATORJWT';
export const USER_SIGNUP = 'user/SIGNUP';
export const USER_DEVICE_LIST = 'user/DEVICELIST';
export const USER_SETPAYMENTMETHOD = 'user/SETPAYMENTMETHOD';
export const USER_SETSELECTCARD = 'user/SETSELECTCARD';
export const USER_SETSAFARIPUSHGRANTED = 'user/SETSAFARIPUSHGRANTED';
export const USER_PREVIOUS_PAGE = 'user/PREVIOUSPAGE';
export const USER_CLASSIFICATION = 'user/CLASSIFICATION';


const initialState = {
	isAuthenticated: (localStorage.u) ? true : false,
	userProfile: (localStorage.u) ? JSON.parse(localStorage.u) : {},
	userCountry: null,
	previousURL: (localStorage.previousURL) ? localStorage.previousURL : null,
	deviceInfo: null,
	isVIP: null,
	isUnifyCountry: false,
	tvCountry: null,
  outsideRegion: null,
  voucherJWT: null,
  searchAccount: null,
  operatorJWT: null,
  deviceList: null,
	paymentMethod: null,
	selectCard: false,
  isSafariPushGranted: false,
  previousPage: (localStorage.previousPage) ? localStorage.previousPage : null,
  userClassificationList: null,
}
// const unifyCountries = ['PHL', 'TWN', 'IDN'];
const unifyCountries = config.unifyCountries.split(',');
let braze = window.braze;

const loginBraze = (token, id, profile) => {
	braze = window.braze;
	userApi.UserGetCountry().then(data => {
		userApi.UserAccountInfo(token, id).then(async(account) => {
			let parentalControl = '';
			if (profile.contactMessage.parentalControl === 'true') {
				let pRes = await parentalApi.ParentalGet(token, window.userCountry);
				parentalControl = pRes.parentalControlLevel || '';
			}
			braze.changeUser(profile.spAccountID);
			let user = braze.getUser();
			braze.requestPushPermission(function() {
				braze.logCustomEvent('send me push');
				braze.requestImmediateDataFlush();
			});
			user.setEmail(profile.contactMessage.email);
			user.setFirstName(profile.contactMessage.firstName);
			user.setLastName(profile.contactMessage.lastName);
			if(profile.contactMessage.dateOfBirth){
				let dateOfBirth = new Date(parseInt(profile.contactMessage.dateOfBirth)).toISOString();
				user.setDateOfBirth(dateOfBirth.split('-')[0], dateOfBirth.split('-')[1], dateOfBirth.split('-')[2].split('T')[0]);
			}
			user.setCustomUserAttribute(`user_territory`, window.userCountry || localStorage.real_territory);
			user.setCustomUserAttribute(`subscription_status`, account.subscriptionStatus === 'true');
			if(parseInt(account.subscriptionExpDateTime) > 1){
				user.setCustomUserAttribute(`subscription_expiry_date_time`, new Date(parseInt(account.subscriptionExpDateTime)));
			}
			if(parseInt(account.nextBillingDateTime) > 1){
				user.setCustomUserAttribute(`subscription_next_billing_date`, new Date(parseInt(account.nextBillingDateTime)));
			}
			if((profile.accountData && (typeof profile.accountData.promotion !== "undefined"))){
				if (profile.accountData.subscriptionStatus === 'false' || (profile.accountData.subscriptionStatus === 'true' && profile.accountData.subscriptionExpDateTime <= new Date().getTime())) {
					user.setCustomUserAttribute(`is_in_free_trial`, false);
				}else{
					user.setCustomUserAttribute(`is_in_free_trial`, true);
				}
			}else{
				user.setCustomUserAttribute(`is_in_free_trial`, false);
			}
			user.setCustomUserAttribute(`payment_method`, profile.accountData.paymentMethod);
			user.setCustomUserAttribute(`channel_partner_id`, profile.channelPartnerID);
			user.setCustomUserAttribute(`operator_name`, profile.operatorName || profile.accountData.operatorName || '');
			user.setCustomUserAttribute(`cp_customer_id`, profile.cpCustomerID);
			user.setCustomUserAttribute(`ev_alert_notification_email`, profile.contactMessage.alertNotificationEmail === 'true');
			user.setCustomUserAttribute(`ev_alert_notification_push`, profile.contactMessage.alertNotificationPush === 'true');
			user.setCustomUserAttribute(`account_registration_date_time`, new Date(parseInt(profile.accountRegistrationDate)));
			user.setCustomUserAttribute(`parental_control`, profile.contactMessage.parentalControl === 'true');
			user.setCustomUserAttribute(`parental_control_level`, parentalControl);
			user.setCustomUserAttribute(`number_of_devices_registered`, account.numDeviceRegistered);
			user.setCustomUserAttribute(`download_count`, account.downloadCount);

			if(window.braze_debug){
				console.log(`changeUser`, profile.spAccountID);
				console.log(`setEmail`, profile.contactMessage.email);
				console.log(`setFirstName`, profile.contactMessage.firstName);
				console.log(`setLastName`, profile.contactMessage.lastName);
				if(profile.contactMessage.dateOfBirth){
					let dateOfBirth = new Date(parseInt(profile.contactMessage.dateOfBirth)).toISOString();
					console.log(`setDateOfBirth`, dateOfBirth.split('-')[0], dateOfBirth.split('-')[1], dateOfBirth.split('-')[2].split('T')[0]);
				}
        		console.log(`user_territory`, window.userCountry || localStorage.real_territory);
				console.log(`subscription_status`, account.subscriptionStatus === 'true');
				console.log(`subscription_expiry_date_time`, account.subscriptionExpDateTime, new Date(parseInt(account.subscriptionExpDateTime)).toISOString());
				console.log(`subscription_next_billing_date`, account.nextBillingDateTime, new Date(parseInt(account.nextBillingDateTime)).toISOString());
				console.log(`is_in_free_trial`, (profile.accountData && (typeof profile.accountData.promotion !== "undefined")));
				console.log(`payment_method`, profile.accountData.paymentMethod);
				console.log(`channel_partner_id`, profile.channelPartnerID);
        		console.log(`operator_name`, profile.operatorName || profile.accountData.operatorName);
				console.log(`cp_customer_id`, profile.cpCustomerID);
				console.log(`ev_alert_notification_email`, profile.contactMessage.alertNotificationEmail === 'true');
				console.log(`ev_alert_notification_push`, profile.contactMessage.alertNotificationPush  === 'true');
				console.log(`account_registration_date_time`, new Date(parseInt(profile.accountRegistrationDate)).toISOString());
				console.log(`parental_control`, profile.contactMessage.parentalControl === 'true');
        		console.log(`parental_control_level`, parentalControl);
				console.log(`number_of_devices_registered`, account.numDeviceRegistered);
				console.log(`download_count`, account.downloadCount);
			}
			localStorage.setItem('braze', true);
		}).catch(error => {
			throw(error);
		});
	}).catch(error => {
		throw(error);
	});
}

const logoutBraze = () => {
	braze = window.braze;
  	braze.unregisterPush();
	localStorage.removeItem('braze');
}

export default (state = initialState, action) => {
	switch (action.type) {
		case USER_SETAUTHENTICATION:
			return {
				...state,
				isAuthenticated: action.payload
			};
		case USER_SETPAYMENTMETHOD:
			return {
				...state,
				paymentMethod: action.payload
			};
		case USER_SETSELECTCARD:
			return {
				...state,
				selectCard: action.payload
			};
		case USER_LOGIN:
			return {
				...state,
				isAuthenticated: true,
				userProfile: action.payload
			};
		case USER_LOGOUT:
			return {
				...state,
				isAuthenticated: false,
			};

		case USER_COUNTRY:
			window.userCountry = action.payload;
			return {
				...state,
				userCountry: action.payload,
				isVIP: false,
				isUnifyCountry: unifyCountries.indexOf(localStorage.getItem('real_territory')) > -1
			};

		case USER_VIP:
			return {
				...state,
				userCountry: action.payload,
				isVIP: true,
				isUnifyCountry: unifyCountries.indexOf(localStorage.getItem('real_territory')) > -1
			};

		case USER_PREVIOUS_URL:
			return {
				...state,
				previousURL: action.payload
			};

		case USER_PROFILE:
			return {
				...state,
				userProfile: action.payload
			};

		case USER_DEVICE_INFO:
			return {
				...state,
				deviceInfo: action.payload
			};

		case USER_OUTSIDEREGION:
			return {
				...state,
				outsideRegion: action.payload
			};

		case USER_TV:
			return {
				...state,
				tvCountry: action.payload
      };

    case USER_VOUCHER_JWT:
      return {
        ...state,
        voucherJWT: action.payload
      }
    case USER_SEARCH_ACCOUNT:
      return {
        ...state,
        searchAccount: action.payload
      }

    case USER_OPERATOR_JWT:
      return {
        ...state,
        operatorJWT: action.payload
      }

    case USER_DEVICE_LIST:
      return {
        ...state,
        deviceList: action.payload
      }

    case USER_SETSAFARIPUSHGRANTED:
      return {
        ...state,
        isSafariPushGranted: action.payload
      }
	
	case USER_PREVIOUS_PAGE:
		return {
			...state,
			previousPage: action.payload
		};

	case USER_CLASSIFICATION:
		return {
			...state,
			userClassificationList: action.payload
		};
      
		default:
			return state;

	}
};

export const userSetAuthentication = (payload) => {
	return dispatch => {
		dispatch({
			type: USER_SETAUTHENTICATION,
			payload
		});
	};
};

export const userLogin = (payload) => {
	return dispatch => {
		return userApi.UserLogin(payload).then(data => {
			delete data.message;
			dispatch({
				type: USER_LOGIN,
				payload: data
			});
			localStorage.setItem('u', JSON.stringify(data));
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userLogout = () => {
	return dispatch => {
		var payload = {
			sessionToken: localStorage.session_token,
			channelPartnerID: localStorage.channel_partner_id,
		};
		logoutBraze();
		localStorage.removeItem('session_token');
		localStorage.removeItem('channel_partner_id');
		localStorage.removeItem('u');
		localStorage.removeItem('watchlist');
		localStorage.removeItem('parentalPINValid');
		dispatch({
			type: USER_LOGOUT
    });
    if (payload.sessionToken && payload.channelPartnerID) {
      return userApi.UserLogout(payload).then(data => {

      }).catch(error => {
        throw(error);
      });
    }
	};
};

export const userForgotPassword = (payload) => {
  return dispatch => {
		return userApi.UserForgotPassword(payload).then(data => {
			delete data.message;
			dispatch({
				type: USER_FORGOTPASSWORD,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
}

export const userResetPassword = (payload) => {
  return dispatch => {
		return userApi.UserResetPassword(payload).then(data => {
			delete data.message;
			dispatch({
				type: USER_RESETPASSWORD,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
}

export const userGetCountry = () => {
	return dispatch => {
		return userApi.UserGetCountry().then(data => {
			let country = '';
			if (data.territory) {
				country = data.territory;
			} else {
				country = data.country;
			}
			if (window.location.origin.indexOf('vip') > -1) {
				if (localStorage.channel_partner_id === 'HBO_SG' || localStorage.channel_partner_id === 'HBO_MY' || localStorage.channel_partner_id === 'HBO_ID' || localStorage.channel_partner_id === 'HBO_HK' || localStorage.channel_partner_id === 'HBO_PH' || localStorage.channel_partner_id === 'HBO_TW') {
					if (localStorage.channel_partner_id === 'HBO_SG') {
						dispatch({
							type: USER_VIP,
							payload: 'SGP',
						});
					} else if (localStorage.channel_partner_id === 'HBO_MY') {
						dispatch({
							type: USER_VIP,
							payload: 'MYS',
						});
					} else if (localStorage.channel_partner_id === 'HBO_ID') {
						dispatch({
							type: USER_VIP,
							payload: 'IDN',
						});
					} else if (localStorage.channel_partner_id === 'HBO_HK') {
						dispatch({
							type: USER_VIP,
							payload: 'HKG',
						});
					} else if (localStorage.channel_partner_id === 'HBO_PH') {
						dispatch({
							type: USER_VIP,
							payload: 'PHL',
						});
					} else if (localStorage.channel_partner_id === 'HBO_TW') {
						dispatch({
							type: USER_VIP,
							payload: 'TWN',
						});
					} else if (localStorage.channel_partner_id === 'HBO_TH') {
						dispatch({
							type: USER_VIP,
							payload: 'THA',
						});
					}
				} else {
					dispatch({
						type: USER_VIP,
						payload: 'SGP',
					});
				}
			} else {
				let res = country;
				if (
						(res !== 'MYS' && res !== 'SGP' && res !== 'IDN' && res !== 'PHL' && res !== 'HKG' && res !== 'TWN' && res !== 'THA')
					|| ((window.location.origin.indexOf('.sg') > -1 || window.location.origin.indexOf('sgweb') > -1) && res !== 'SGP')
					|| ((window.location.origin.indexOf('.my') > -1 || window.location.origin.indexOf('myweb') > -1) && res !== 'MYS')
					|| ((window.location.origin.indexOf('.id') > -1 || window.location.origin.indexOf('idweb') > -1) && res !== 'IDN')
					|| ((window.location.origin.indexOf('.ph') > -1 || window.location.origin.indexOf('phweb') > -1) && res !== 'PHL')
					|| ((window.location.origin.indexOf('.hk') > -1 || window.location.origin.indexOf('hkweb') > -1) && res !== 'HKG')
					|| ((window.location.origin.indexOf('.tw') > -1 || window.location.origin.indexOf('twweb') > -1) && res !== 'TWN')
					|| ((window.location.origin.indexOf('.th') > -1 || window.location.origin.indexOf('thweb') > -1) && res !== 'THA')
					) {
					dispatch({
						type: USER_OUTSIDEREGION,
						payload: true,
					});
				} else {
					dispatch({
						type: USER_OUTSIDEREGION,
						payload: false,
					});
				}

				dispatch({
					type: USER_COUNTRY,
					payload: country,
				});
			}

			dispatch({
				type: USER_TV,
				payload: data.tv || false,
			});

			return country;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userSetPreviousURL = (payload) => {
	return dispatch => {
		if(payload){
			localStorage.setItem('previousURL', payload);
		}else{
			localStorage.removeItem('previousURL');
		}
		dispatch({
			type: USER_PREVIOUS_URL,
			payload
		});
	};
};

export const userSetPreviousPage = (payload) => {
	return dispatch => {
		if(payload){
			localStorage.setItem('previousPage', payload);
		}else{
			localStorage.removeItem('previousPage');
		}
		dispatch({
			type: USER_PREVIOUS_PAGE,
			payload
		});
	};
};

export const userSetBeforePlay = (payload) => {
	return dispatch => {
		if(payload){
			localStorage.setItem('beforePlay', JSON.stringify(payload));
		}else{
			localStorage.removeItem('beforePlay');
		}
	}
}

export const userGetBeforePlay = () => {
	return dispatch => {
		return JSON.parse(localStorage.getItem('beforePlay'));
	}
}

export const userGetProfile = (token, id, microsite, sess) => {
	return dispatch => {
		return userApi.UserGetProfile(token, id, sess).then(data => {
			delete data.message;
			dispatch({
				type: USER_PROFILE,
				payload: data,
			});

			if (!microsite) {
				if(!localStorage.braze){
					loginBraze(token, id, data);
				}
				localStorage.setItem('u', JSON.stringify(data));
			}

			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userGetSubscriptionStatus = (res) => {
	let subscriptionStatus = "active";
	if (res.accountData.accountStatus === 'Hold' && (res.subscriptionHistory.AccountServiceMessage[0].paymentMethod === 'Google Wallet' || res.subscriptionHistory.AccountServiceMessage[0].paymentMethod === 'App Store Billing')) {
		subscriptionStatus = 'account_hold';
	} else if (res.accountData.subscriptionStatus === 'false' && res.accountData.subscriptionExpDateTime === '-1') {
		subscriptionStatus = 'abandoner';
	} else if (Number.parseInt(res.accountData.validityTill, 10) === -1) {
		if (localStorage.channel_partner_id.includes('HBO_D2C')) {
			subscriptionStatus = 'no_subscription';
		} else if (localStorage.channel_partner_id.includes('HBO_')) {
			subscriptionStatus = 'no_subscription_guest';
		} else {
			subscriptionStatus = 'no_subscription_operator';
		}
	} else if (res.accountData.status === 'Final Bill' && Number.parseInt(res.accountData.validityTill, 10) === 0 && res.accountData.promotion) {
		subscriptionStatus = 'after_promo_cancel';
	} else if (res.accountData.status === 'Final Bill' && Number.parseInt(res.accountData.validityTill, 10) === 0 && !res.accountData.promotion) {
		if (localStorage.channel_partner_id.includes('HBO_D2C')) {
			subscriptionStatus = 'normal_cancel_IAP';
		} else if (localStorage.channel_partner_id.includes('HBO_')) {
			subscriptionStatus = 'no_subscription_guest';
		} else {
			subscriptionStatus = 'normal_cancel';
		}
	} else if (res.accountData.subscriptionStatus === 'false' || (res.accountData.subscriptionStatus === 'true' && res.accountData.subscriptionExpDateTime <= new Date().getTime())) {
		subscriptionStatus = 'lapsed';
	}
	return subscriptionStatus;
};

export const userAuthenticateCustomerForOperator = (token, id) => {
	return dispatch => {
		return userApi.UserAuthenticateCustomerForOperator(token, id).then(data => {
			delete data.message;
			dispatch({
				type: USER_SESSION,
				payload: data,
			});

			// if (window.location.hash !== '#activate') {
				localStorage.setItem('u', JSON.stringify(data));
			// }

			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userSetDeviceInfo = (payload) => {
	return dispatch => {
		dispatch({
			type: USER_DEVICE_INFO,
			payload
		});
	};
};

export const userCreateVoucherJWT = (token, id) => {
  return dispatch => {
		return userApi.UserCreateVoucherJWT(token, id).then(data => {
			delete data.message;
			dispatch({
				type: USER_VOUCHER_JWT,
				payload: data,
			});

			return data;
		}).catch(error => {
			throw(error);
		});
	};
}

export const userSearchAccount = (payload) => {
  return dispatch => {
    return userApi.UserSearchAccount(payload).then(data => {
      dispatch({
				type: USER_SEARCH_ACCOUNT,
				payload: data,
			});
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userUpdateProfile = (payload) => {
  return dispatch => {
    return userApi.UserUpdateProfile(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userPatchProfile = (payload) => {
  return dispatch => {
    return userApi.UserPatchProfile(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userValidateOperatorJWT = (payload) => {
  return dispatch => {
    return userApi.UserValidateOperatorJWT(payload).then(data => {
      dispatch({
				type: USER_OPERATOR_JWT,
				payload: data,
			});
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userLinkOperatorAccount = (payload) => {
  return dispatch => {
    return userApi.UserLinkOperatorAccount(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userSignIn = (payload, lang = 'en') => {
  return dispatch => {
    return userApi.UserSignIn(payload, lang).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userSignUp = (payload, lang = 'en') => {
  return dispatch => {
    return userApi.UserSignUp(payload, lang).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userGetPaymentModes = (payload, lang = 'en') => {
  return dispatch => {
    return userApi.UserGetPaymentModes(payload, lang).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userGetProducts = (token, id, storeType='creditcard') => {
  return dispatch => {
    return userApi.UserGetProducts(token, id, storeType).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}


export const userAgeDeclaration = (payload, lang = 'en') => {
  return dispatch => {
    return userApi.UserAgeDeclaration(payload, lang).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userCreateParentalControl = (payload, lang = 'en') => {
  return dispatch => {
    return userApi.UserCreateParentalControl(payload, lang).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userClassification = (filter) => {
  return dispatch => {
    return userApi.UserClassification(filter).then(data => {
	  dispatch({
		type: USER_CLASSIFICATION,
		payload: data,
	  })
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userChangePassword = (payload) => {
  return dispatch => {
    return userApi.UserChangePassword(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userGetDevice = (payload) => {
  return dispatch => {
    return userApi.UserGetDevice(payload).then(data => {
      dispatch({
				type: USER_DEVICE_LIST,
				payload: data,
			});
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userUpdateDeviceDetail = (payload) => {
  return dispatch => {
    return userApi.UserUpdateDeviceDetail(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userDeleteDevice = (payload) => {
  return dispatch => {
    return userApi.UserDeleteDevice(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userGetSubscription = (payload) => {
  return dispatch => {
    return userApi.UserGetSubscription(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const adyenPayments = (payload) => {
  return dispatch => {
    return userApi.AdyenPayments(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userGetPaymentMethods = (payload) => {
  return dispatch => {
    return userApi.UserGetPaymentMethods(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userSetPaymentMethod = (payload) => {
	return dispatch => {
		dispatch({
			type: USER_SETPAYMENTMETHOD,
			payload
		});
	};
};

export const userSetSelectCard = (payload) => {
	return dispatch => {
		dispatch({
			type: USER_SETSELECTCARD,
			payload
		});
	};
};

export const userAddSubscription = (payload) => {
  return dispatch => {
    return userApi.UserAddSubscription(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}



export const userUpdatePaymentInfo = (payload) => {
  return dispatch => {
    return userApi.UserUpdatePaymentInfo(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}



export const userGetSubscriptionHistory = (payload) => {
  return dispatch => {
    return userApi.UserGetSubscriptionHistory(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userCheckCredentials = (payload) => {
  return dispatch => {
    return userApi.UserCheckCredentials(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userChangeService = (payload) => {
  return dispatch => {
    return userApi.UserChangeService(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userRemoveSubscription = (payload) => {
  return dispatch => {
    return userApi.UserRemoveSubscription(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}


export const userIsCustomerEligibleForFreeTrial = (payload) => {
  return dispatch => {
    return userApi.UserIsCustomerEligibleForFreeTrial(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userIsCustomerEligibleForPromotion = (payload) => {
  return dispatch => {
    return userApi.UserIsCustomerEligibleForPromotion(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userRemoveAllDevices = (payload) => {
  return dispatch => {
    logoutBraze();
    localStorage.removeItem('session_token');
    localStorage.removeItem('channel_partner_id');
    localStorage.removeItem('u');
    localStorage.removeItem('watchlist');
    localStorage.removeItem('parentalPINValid');
    dispatch({
      type: USER_LOGOUT
    });
    return userApi.UserRemoveAllDevices(payload).then(data => {
      return data;
    }).catch((error) => {
      throw(error);
    })
  }
}

export const userSetSafariPushGranted = (data) => {
  return dispatch => {
    let payload = data || (window.braze && window.braze.isPushPermissionGranted()) || window.braze.isPushBlocked();
    console.log('isPushPermissionGranted()', window.braze.isPushPermissionGranted(), 'isPushBlocked()', window.braze.isPushBlocked());
    dispatch({
			type: USER_SETSAFARIPUSHGRANTED,
			payload
		});
    return payload;
  }
}

export const userInitialBraze = () => {
  return dispatch => {
		braze = window.braze;
		braze.initialize(config.brazeApiKey, {
			enableLogging: false,
			baseUrl: "https://sdk.iad-06.braze.com",
			serviceWorkerLocation: '/braze-service-worker.js',
			safariWebsitePushId: config.safariPushId,
		});
		braze.subscribeToInAppMessage(function(inAppMessage) {
			if (inAppMessage.buttons.length > 0) {
				if (inAppMessage.buttons[0]) {
				inAppMessage.buttons[0].subscribeToClickedEvent(() => {
					gtmModule('HBOGO-Engagement', 'InAppMessage', {
					label: 'Label=ClickButton1'
					}, false);
				});
				}
				if (inAppMessage.buttons[1]) {
				inAppMessage.buttons[1].subscribeToClickedEvent(() => {
					gtmModule('HBOGO-Engagement', 'InAppMessage', {
					label: 'Label=ClickButton2'
					}, false);
				});
				}
			}
			inAppMessage.subscribeToDismissedEvent(() => {
				gtmModule('HBOGO-Engagement', 'InAppMessage', {
				label: 'Label=ClickClose'
				}, false);
			});
			braze.showInAppMessage(inAppMessage);
		});
		braze.openSession();
		if (localStorage.u) {
			let profile = JSON.parse(localStorage.u);
			braze.changeUser(profile.spAccountID);
			braze.requestPushPermission(function() {
				braze.requestImmediateDataFlush();
				dispatch(userSetSafariPushGranted());
			}, function () {
				dispatch(userSetSafariPushGranted());
			});
		}
	};
}

export const checkRemotePermission = (permissionData) => {
  return dispatch => {
    console.log('check Permission', permissionData.permission);
    if (permissionData.permission === 'default') {
        let profile = JSON.parse(localStorage.u);
        // This is a new web service URL and its validity is unknown.
        window.safari.pushNotification.requestPermission(
            "https://app-release.onwardsmg.com/safari", // The web service URL.
            config.safariPushId,     // The Website Push ID.
            {
              "spAccountID": profile.spAccountID,
            }, // Data that you choose to send to your server to help you identify the user.
            function(e) {
              console.log('after requestPermission', e.permission);
              dispatch(userSetSafariPushGranted());
              if (e.permission === 'granted' || e.permission === 'denied') {
                dispatch(userSetSafariPushGranted(true));
              }
            }         // The callback function.
        );
    }
    else if (permissionData.permission === 'denied') {
        // The user said no.
    }
    else if (permissionData.permission === 'granted') {
        // The web service URL is a valid push provider, and the user said yes.
        // permissionData.deviceToken is now available to use.
        dispatch(userSetSafariPushGranted(true));
    }
  }
};
