import translationApi from './api/translationApi';

export const TRANSLATION_LIST = 'translation/LIST';


const initialState = {
	translationList: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TRANSLATION_LIST:
			return {
				...state,
				translationList: action.payload
			};

		default:
			return state;
		
	}
};

export const translationGetList = (country) => {
	return dispatch => {
		return translationApi.translationGetList(country).then(data => {
			dispatch({
				type: TRANSLATION_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
