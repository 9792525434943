import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const settingInstance = apiInstance;

class SettingApi {
	static SettingGetProfile(payload) {
		// settingInstance.defaults.headers.common['Authorization'] = tokenApi.GetToken();

		return settingInstance.post(`/hbouser/getProfile?lang=${localStorage.default_lang}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default SettingApi;
