import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const watchlistInstance = apiInstance;

class WatchlistApi {
	static WatchlistGetList(country, token, sortBy, sortOrder) {
		let sortByUrl = '';
		let sortOrderUrl = '';

		if (sortBy) {
			sortByUrl = `&sortBy=${sortBy}`;
		}

		if (sortOrder) {
			sortOrderUrl = `&sortOrder=${sortOrder}`;
		}

		return watchlistInstance.get(`/hbouser/watchlist?territory=${country}&sessionToken=${token}&channelPartnerID=${localStorage.channel_partner_id}&multiProfileId=0${sortByUrl}${sortOrderUrl}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			if(error.response.status === 401){
				localStorage.removeItem('u');
				localStorage.removeItem('session_token');
				localStorage.removeItem('channel_partner_id');
				window.parent.location = `${window.parent.location.origin}/`;
			}
			throw(error);
		});
	}

	static WatchlistDelete(id, payload) {
		return watchlistInstance.patch(`/hbouser/watchlist/${id}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static WatchlistDeleteAll(payload) {
		return watchlistInstance.post('/hbouser/watchlist/removeAll', payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

  static WatchlistDeleteMultiple(payload) {
		return watchlistInstance.post('/hbouser/watchlist/remove', payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static WatchlistInsert(payload) {
		return watchlistInstance.post('/hbouser/watchlist', payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default WatchlistApi;
