import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const movieInstance = apiInstance;

class MovieApi {
	static MovieGetContent(country) {
		return movieInstance.get(`/movie/list?territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static MovieGetSomeContent(country, genre, page, perPage) {
		return movieInstance.get(`/movie/list?territory=${country}&genre=${genre}&page=${page}&perPage=${perPage}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

	static MovieGetSpecific(country, id) {
		return movieInstance.get(`/movie?contentId=${id}&territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default MovieApi;
