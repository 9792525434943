import movieApi from './api/movieApi';

export const MOVIE_CONTENT = 'movie/CONTENT';
export const MOVIE_SPECIFIC = 'movie/SPECIFIC';

const initialState = {
	movieContent: null,
	movieSpecific: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case MOVIE_CONTENT:
			return {
				...state,
				movieContent: action.payload
			};

		case MOVIE_SPECIFIC:
			return {
				...state,
				movieSpecific: action.payload
			};

		default:
			return state;
		
	}
};

export const movieGetContent = (country) => {
	return dispatch => {
		return movieApi.MovieGetContent(country).then(data => {
			dispatch({
				type: MOVIE_CONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const movieGetSomeContent = (country, genre, page, perPage) => {
	return dispatch => {
		return movieApi.MovieGetSomeContent(country, genre, page, perPage).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const movieGetSpecific = (country, id) => {
	return dispatch => {
		return movieApi.MovieGetSpecific(country, id).then(data => {
			dispatch({
				type: MOVIE_SPECIFIC,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
