import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const thematicInstance = apiInstance;

class RecommendationApi {
	static RecommendationGetList(sessionToken, channelPartnerID, territory, itemsCount) {
		let params = `?sessionToken=${sessionToken}&channelPartnerID=${channelPartnerID}&territory=${territory}`;
		if (itemsCount && itemsCount > 0) {
			params += `&itemsCount=${itemsCount}`;
		}
		return thematicInstance.get(`/recommendation${params}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default RecommendationApi;
