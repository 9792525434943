import layoutApi from './api/layoutApi';

export const LAYOUT_CONTENT = 'layout/CONTENT';
export const LAYOUT_HOME = 'layout/HOME';
export const LAYOUT_ROOT = 'layout/ROOT';
export const LAYOUT_LANDING_SERIES = 'layout/LANDING_SERIES';
export const LAYOUT_LANDING_MOVIES = 'layout/LANDING_MOVIES';
export const LAYOUT_LANDING_KIDS_FAMILY = 'layout/LANDING_KIDS_FAMILY';

const initialState = {
	layoutContent: null,
	layoutHome: null,
	layoutRoot: null,
	layoutLandingSeries: null,
	layoutLandingMovies: null,
	layoutLandingKidsFamily: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case LAYOUT_CONTENT:
			return {
				...state,
				layoutContent: action.payload
			};
		case LAYOUT_HOME:
			return {
				...state,
				layoutHome: action.payload
			};
		case LAYOUT_ROOT:
			return {
				...state,
				layoutRoot: action.payload
			};
		case LAYOUT_LANDING_SERIES:
			return {
				...state,
				layoutLandingSeries: action.payload
			};
		case LAYOUT_LANDING_MOVIES:
			return {
				...state,
				layoutLandingMovies: action.payload
			};
		case LAYOUT_LANDING_KIDS_FAMILY:
			return {
				...state,
				layoutLandingKidsFamily: action.payload
			};
		default:
			return state;

	}
};

export const layoutGetRoot = (country) => {
	return dispatch => {
		return layoutApi.LayoutGetRoot(country).then(data => {
			dispatch({
				type: LAYOUT_ROOT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const layoutGetContent = (country, page) => {
	return dispatch => {
		return layoutApi.LayoutGetContent(country, page).then(data => {
			dispatch({
				type: (page === 'mobile')?LAYOUT_HOME:LAYOUT_CONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const layoutGetLandingSeries = (country, page) => {
	return dispatch => {
		return layoutApi.LayoutGetLandingSeries(country, page).then(data => {
			dispatch({
				type: LAYOUT_LANDING_SERIES,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const layoutGetLandingMovies = (country, page) => {
	return dispatch => {
		return layoutApi.LayoutGetLandingMovies(country, page).then(data => {
			dispatch({
				type: LAYOUT_LANDING_MOVIES,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const layoutGetLandingKidsFamily = (country, page) => {
	return dispatch => {
		return layoutApi.LayoutGetLandingKidsFamily(country, page).then(data => {
			dispatch({
				type: LAYOUT_LANDING_KIDS_FAMILY,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
