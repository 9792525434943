import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const videoInstance = apiInstance;

class VideoApi {

	static VideoGetListTrending(filter) {
		return videoInstance.get(`/video?perPage=100&tags=${filter}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static VideoGetList(filter) {
		return videoInstance.get(`/video?perPage=100&genres=${filter}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static VideoGetSpecific(id) {
		return videoInstance.get(`/video/${id}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static VideoGetExtra(id) {
		return videoInstance.get(`/video/extra/${id}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default VideoApi;
