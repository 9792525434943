import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const searchInstance = apiInstance;

class SearchApi {
	static SearchGetGenre(country, genre, sortBy, sortOrder) {
		return searchInstance.get(`/allsearch?territory=${country}&genre=${genre}&sortBy=${sortBy}&sortOrder=${sortOrder}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static SearchGetMovieSeries(country, genre, sortBy, sortOrder, sortType) {
		return searchInstance.get(`/allsearch?territory=${country}&genre=${genre}&sortBy=${sortBy}&sortOrder=${sortOrder}&type=${sortType}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static SearchGetContent(country, id) {
		return searchInstance.get(`/allsearch?territory=${country}&contentId=${id}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static SearchGetFreeContent(country, sortBy, sortOrder) {
		return searchInstance.get(`/allsearch?territory=${country}&freeContent=true&sortBy=${sortBy}&sortOrder=${sortOrder}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static SearchGetResults(country, title) {
		return searchInstance.get(`/allsearch?territory=${country}&search=${title}&hidePromo=true`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default SearchApi;
