import voucherApi from './api/voucherApi';

export const VOUCHER_STORESESSION = 'voucher/STORESESSION';

const initialState = {
	voucherSession: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case VOUCHER_STORESESSION:
			return {
				...state,
				voucherSession: action.payload
			};

		default:
			return state;
		
	}
};

export const voucherCheck = (payload) => {
	return dispatch => {
		return voucherApi.VoucherCheck(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const voucherStoreSession = (payload) => {
	return dispatch => {
		dispatch({
			type: VOUCHER_STORESESSION,
			payload
		});
	};
};
