import micrositeApi from './api/micrositeApi';

export const MICROSITE_SPECIFIC = 'microsite/SPECIFIC';
export const MICROSITE_VALID = 'microsite/VALID';

const initialState = {
  micrositeSpecific: null,
  isCouponValid: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case MICROSITE_SPECIFIC:
			return {
				...state,
				micrositeSpecific: action.payload
			};
    case MICROSITE_VALID:
      return {
        ...state,
        isCouponValid: action.payload
      };
		default:
			return state;
	}
};

export const micrositeGetSpecific = (path, previewCode, country) => {
  return dispatch => {
		return micrositeApi.MicrositeGet(path, previewCode, country).then(data => {
			dispatch({
				type: MICROSITE_SPECIFIC,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
}

export const micrositeIsValidCoupon = (channelPartnerID, couponCode, isValidCoupon = false) => {
  return dispatch => {
    var payload = {
			channelPartnerID: channelPartnerID,
			couponCode: couponCode,
      isValidCoupon: isValidCoupon
    };
    return micrositeApi.IsValidCoupon(payload).then(data => {
      dispatch({
				type: MICROSITE_VALID,
				payload: data
      });

      return data;
    }).catch(error => {
			throw(error);
		});
  }
}

export const micrositeSetCoupon = (data) => {
  return dispatch => {
    dispatch({
      type: MICROSITE_VALID,
      payload: data
    });

    return data;
  }
}

export const micrositeClearCoupon = () => {
  return dispatch => {
    dispatch({
      type: MICROSITE_VALID,
      payload: null
    });

    return null;
}
}

export const micrositeRedeemCoupon = (channelPartnerID, sessionToken, couponCode, recaptcha) => {
  return dispatch => {
    var payload = {
      channelPartnerID: channelPartnerID,
      sessionToken: sessionToken,
			couponCode: couponCode,
      source: (window.location.pathname === '/billing')?window.location.host:window.location.host+window.location.pathname
    };
    if (recaptcha) {
      payload['recaptcha'] = recaptcha;
    }
    return micrositeApi.RedeemCoupon(payload).then(data => {
      return data;
    }).catch(error => {
			throw(error);
		});
  }
}
