import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const historyInstance = apiInstance;

class HistoryApi {
	static HistoryGetList(country, token, sortBy, sortOrder) {
		let sortByUrl = '';
		let sortOrderUrl = '';

		if (sortBy) {
			sortByUrl = `&sortBy=${sortBy}`;
		}

		if (sortOrder) {
			sortOrderUrl = `&sortOrder=${sortOrder}`;
		}

		return historyInstance.get(`/viewhistory?territory=${country}&sessionToken=${token}&channelPartnerID=${localStorage.channel_partner_id}&multiProfileId=0${sortByUrl}${sortOrderUrl}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static HistoryInsert(payload) {
		return historyInstance.post('/viewhistory', payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default HistoryApi;
