import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const seriesInstance = apiInstance;

class SeriesApi {
	static SeriesGetContent(country) {
		return seriesInstance.get(`/tvseries/list?territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
  }

  static SeriesGetSomeContent(country, genre, page, perPage) {
		let genreFilter = '';
		if (genre) {
			genreFilter = `&genre=${genre}`;
		}
		return seriesInstance.get(`/tvseries/list?territory=${country}${genreFilter}&page=${page}&perPage=${perPage}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static SeriesGetSpecific(country, id, header) {
		return seriesInstance.get(`/tvseries?contentId=${id}&territory=${country}`+(header?`&header=`+header:''))
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static SeriesGetSeasonList(country, id) {
		return seriesInstance.get(`/tvseason/list?parentId=${id}&territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

  static SeriesGetSeason(country, id) {
		return seriesInstance.get(`/tvseason/list?contentId=${id}&territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static SeriesGetEpisodeList(country, id) {
		return seriesInstance.get(`/tvepisode/list?parentId=${id}&territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static SeriesGetEpisode(country, id) {
		return seriesInstance.get(`/tvepisode?contentId=${id}&territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default SeriesApi;
