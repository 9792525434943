import collectionApi from './api/collectionApi';
import layoutApi from './api/layoutApi';

export const COLLECTION_CAROUSEL = 'collection/CAROUSEL';
export const COLLECTION_HOME_CONTENT = 'collection/HOMECONTENT';
export const COLLECTION_MOVIE_CONTENT = 'collection/MOVIECONTENT';
export const COLLECTION_SERIES_CONTENT = 'collection/SERIESCONTENT';
export const COLLECTION_CONTENT = 'collection/CONTENT';
export const COLLECTION_LAYOUT_HOME = 'collection/LAYOUTHOME';

const initialState = {
	collectionCarousel: null,
	collectionHomeContent: null,
	collectionMovieContent: null,
	collectionSeriesContent: null,
	collectionContent: null,
  collectionLayoutHome: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case COLLECTION_CAROUSEL:
			return {
				...state,
				collectionCarousel: action.payload
			};

		case COLLECTION_HOME_CONTENT:
			return {
				...state,
				collectionHomeContent: action.payload
			};

		case COLLECTION_MOVIE_CONTENT:
			return {
				...state,
				collectionMovieContent: action.payload
			};

		case COLLECTION_SERIES_CONTENT:
			return {
				...state,
				collectionSeriesContent: action.payload
			};

		case COLLECTION_CONTENT:
			return {
				...state,
				collectionContent: action.payload
			};

    case COLLECTION_LAYOUT_HOME:
      return {
        ...state,
        collectionLayoutHome: action.payload
      };

		default:
			return state;

	}
};

export const collectionGetCarousel = (country, page) => {
	return dispatch => {
		return collectionApi.CollectionGetContent(country, page).then(data => {
			dispatch({
				type: COLLECTION_CAROUSEL,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const collectionGetHomeContent = (country, page) => {
	return dispatch => {
		return collectionApi.CollectionGetContent(country, page).then(data => {
			dispatch({
				type: COLLECTION_HOME_CONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const collectionLayoutGetHomeContent = (country, page) => {
	return () => {
		return collectionApi.CollectionGetContent(country, `layout/${page}`).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const collectionGetMovieContent = (country, page) => {
	return dispatch => {
		return collectionApi.CollectionGetContent(country, page).then(data => {
			dispatch({
				type: COLLECTION_MOVIE_CONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const collectionGetSeriesContent = (country, page, isDispatch = true) => {
	return dispatch => {
		return collectionApi.CollectionGetContent(country, page).then(data => {
			if(isDispatch){
				dispatch({
					type: COLLECTION_SERIES_CONTENT,
					payload: data,
				});				
			}
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const collectionGetContent = (country, page, query = '') => {
	return dispatch => {
		return collectionApi.CollectionGetContent(country, page, query).then(data => {
			dispatch({
				type: COLLECTION_CONTENT,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const collectionGetLayoutHome = (country) => {
	return dispatch => {
		return collectionApi.CollectionGetLayout(country, 'mobile').then(data => {
      if (data.rails && data.rails.length) {
        data.items = [];
        data.rails.map((rail, i) => {
			let railId = rail.content_id;
          if (i === 0) {
            collectionApi.CollectionGetContent(country, `${railId}`).then(item => {
              var itemarr = [];
              item.items.map(d=> {
                if (d.contentType === 'series') {
                  itemarr.push(`sr${d.contentId}`)
                } else if (d.contentType === 'production' || d.contentType === 'episode' || d.contentType === 'promo'){
                  itemarr.push(d.contentId)
                } else {
                  console.error('[collectionGetLayoutHome] - item not added to rail, contentType:', d.contentType, d.contentId)
                }
                return d;
              });
              item.items = itemarr;
              data.items[i] = {...item};
            }).catch(()=> {
              console.error('[collectionGetLayoutHome] - collection not found', railId)
            })
          } else {
            collectionApi.CollectionGetContent(country, `${railId}`).then(collectionlayoutdata => {
              data.items[i] = {content_id: railId, collectionlayoutdata: collectionlayoutdata};
            }).catch(() => {
              data.items[i] = {content_id: railId};
            })
          }
          return railId;
        })
      }
			dispatch({
				type: COLLECTION_LAYOUT_HOME,
				payload: data,
			});
			return data;
		}).catch(error => {
			return layoutApi.LayoutGetContent(country, 'mobile').then(data => {
        dispatch({
          type: COLLECTION_LAYOUT_HOME,
          payload: data,
        });
        return data;
      })
		});
	};
};

export const collectionGetLayout = (country, id) => {
	return dispatch => {
		return collectionApi.CollectionGetLayout(country, id).then(data => {
			if (data.rails && data.rails.length) {
				data.rails.map((rail, i) => {
					let railId = rail.content_id;
				  if (i === 0 &&  data.masthead && data.masthead.style === 'spotlight') {
					collectionApi.CollectionGetContent(country, `${railId}`).then(item => {
					  var itemarr = [];
					  item.items.map(d=> {
						if (d.contentType === 'series') {
						  itemarr.push(`sr${d.contentId}`)
						} else if (d.contentType === 'production' || d.contentType === 'episode' || d.contentType === 'promo'){
						  itemarr.push(d.contentId)
						} else {
						  console.error('[collectionGetLayoutHome] - item not added to rail, contentType:', d.contentType, d.contentId)
						}
						return d;
					  });
					  item.items = itemarr;
					  data.rails[i] = {...item};
					}).catch(()=> {
					  console.error('[collectionGetLayoutHome] - collection not found', railId)
					})
				  } else {
					collectionApi.CollectionGetContent(country, `${railId}`).then(collectionlayoutdata => {
					  data.rails[i] = {content_id: railId, collectionlayoutdata: collectionlayoutdata};
					}).catch(() => {
					  data.rails[i] = {content_id: railId};
					})
				  }
				  return railId;
				})
			}
			return data;
		}).catch(error => {
			throw(error);
		});
	};
}