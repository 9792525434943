import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const layoutInstance = apiInstance;

class LayoutApi {
	static LayoutGetRoot(country) {
		return layoutInstance.get(`/layout/rootItems?territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static LayoutGetContent(country, page) {
		return layoutInstance.get(`/layout/${page}?territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static LayoutGetLandingSeries(country) {
		return layoutInstance.get(`/layout/series_landing?territory=${country}&lang=${localStorage.default_lang}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static LayoutGetLandingMovies(country) {
		return layoutInstance.get(`/layout/movies_landing?territory=${country}&lang=${localStorage.default_lang}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static LayoutGetLandingKidsFamily(country) {
		return layoutInstance.get(`/layout/large-screen-kids_family?territory=${country}&lang=${localStorage.default_lang}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default LayoutApi;
