import playbackApi from './api/playbackApi';

export const PLAYBACK_URLLIST = 'playback/URLLIST';

const initialState = {
	playbackURLList: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PLAYBACK_URLLIST:
			return {
				...state,
				playbackURLList: action.payload
			};

		default:
			return state;

	}
};

export const playbackGetURLList = (country, id, token, operatorId) => {
	return dispatch => {
		return playbackApi.PlaybackGetURLList(country, id, token, operatorId).then(data => {
			dispatch({
				type: PLAYBACK_URLLIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const playbackGetExtraURLList = (country, id, operatorId) => {
	return dispatch => {
		return playbackApi.PlaybackGetExtraURLList(country, id, operatorId).then(data => {
			dispatch({
				type: PLAYBACK_URLLIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
