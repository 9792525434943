import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const playbackInstance = apiInstance;

class PlaybackApi {
	static PlaybackGetURLList(country, id, token, operatorId) {
		// return playbackInstance.get(`/asset/playbackurl?territory=${country}&contentId=${id}&sessionToken=${token}&operatorId=${operatorId}&lang=${localStorage.default_lang}`)
		return playbackInstance.get(`/asset/playbackurl?territory=${country}&contentId=${id}&sessionToken=${token}&channelPartnerID=${localStorage.channel_partner_id}&operatorId=${operatorId}&lang=${localStorage.default_lang}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static PlaybackGetExtraURLList(country, id, operatorId) {
		return playbackInstance.get(`/asset/playbackurl?territory=${country}&contentId=${id}&operatorId=${operatorId}&lang=${localStorage.default_lang}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default PlaybackApi;
