import ratingApi from './api/ratingApi';

export const RATING_LIST = 'rating/LIST';

const initialState = {
	ratingList: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case RATING_LIST:
			return {
				...state,
				ratingList: action.payload
			};

		default:
			return state;
		
	}
};

export const ratingGetList = (token, id) => {
	return dispatch => {
		return ratingApi.RatingGetList(token, id).then(data => {
			dispatch({
				type: RATING_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const ratingGetListWithoutToken = (id) => {
	return dispatch => {
		return ratingApi.RatingGetListWithoutToken(id).then(data => {
			dispatch({
				type: RATING_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const ratingInsert = (payload) => {
	return dispatch => {
		return ratingApi.RatingInsert(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
