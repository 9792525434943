import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const translationInstance = apiInstance;

class translationApi {
	static translationGetList(country) {
		return translationInstance.get(`/translation/?type=web&territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}
}

export default translationApi;
