import partnerApi from './api/partnerApi';

export const PARTNER_SPECIFIC = 'partner/SPECIFIC';
export const PARTNER_CLEAR = 'partner/CLEAR';
export const PARTNER_VALID = 'partner/VALID';

const initialState = {
  partnerSpecific: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PARTNER_SPECIFIC:
			return {
				...state,
				partnerSpecific: action.payload
			};
		case PARTNER_CLEAR:
			return {
				...state,
				partnerSpecific: null
			};
		default:
			return state;
	}
};

export const partnerGetSpecific = (path, previewCode, country) => {
  return dispatch => {
		return partnerApi.PartnerGet(path, previewCode, country).then(data => {
      dispatch({
        type: PARTNER_SPECIFIC,
        payload: data,
      });
			return data;
		}).catch(error => {
			throw(error);
		});
	};
}

export const partnerGetCountry = (country) => {
  return dispatch => {
		return partnerApi.PartnerGetCountry(country).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
}


export const partnerLeave = () => {
  return dispatch => {
		dispatch({
			type: PARTNER_CLEAR
		});
	};
}
