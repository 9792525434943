import sysinfoApi from './api/sysinfoApi';

export const SYSINFO_SIGNINFORFREECONTENT = 'sysinfo/SIGNINFORFREECONTENT';
export const SYSINFO_THUMBNAILPATH = 'sysinfo/SYSINFOTHUMBNAILPATH';
export const SYSINFO_HBOHELPMAIL = 'sysinfo/SYSINFOHBOHELPMAIL'
export const SYSINFO_CONVIVA = 'sysinfo/SYSINFOCONVIVA';
export const SYSINFO_IMPRESSION = 'sysinfo/SYSINFOIMPRESSION';
export const SYSINFO_RECOMMENDATION = 'sysinfo/SYSINFORECOMMENDATION';
export const SYSINFO_DELETEACC = 'sysinfo/SYSINFODELETEACC';
export const SYSINFO_All_CONFIG = 'sysinfo/SYSINFOALLCONFIG';

const initialState = {
	sysInfoSignInForFreeContent: null,
	sysInfoThumbnailPath: null,
	sysInfoHboHelpMail: null,
	sysInfoConviva: null,
	sysInfoImpression: null,
	sysInfoRecommendation: null,
	sysInfoDeleteAcc: null,
	sysInfoAllConfig: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case SYSINFO_SIGNINFORFREECONTENT:
			return {
				...state,
				sysInfoSignInForFreeContent: action.payload
			};

		case SYSINFO_THUMBNAILPATH:
			return {
				...state,
				sysInfoThumbnailPath: action.payload
			};
		case SYSINFO_HBOHELPMAIL:
			return {
				...state,
				sysInfoHboHelpMail: action.payload
			};
		case SYSINFO_CONVIVA:
			return {
				...state,
				sysInfoConviva: action.payload
			};
		case SYSINFO_IMPRESSION:
			return {
				...state,
				sysInfoImpression: action.payload
			};
		case SYSINFO_RECOMMENDATION:
			return {
				...state,
				sysInfoRecommendation: action.payload
			}
		case SYSINFO_DELETEACC:
			return {
				...state,
				sysInfoDeleteAcc: action.payload
			}
		case SYSINFO_All_CONFIG:
			return {
				...state,
				sysInfoAllConfig: action.payload
			}

		default:
			return state;
		
	}
};

export const sysInfoGetAllConfig = () => {
	return dispatch => {
		return sysinfoApi.SysInfoGetAllSysConfig().then(data => {
			dispatch({
				type: SYSINFO_All_CONFIG,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const sysInfoGetSignInForFreeContent = () => {
	return dispatch => {
		return sysinfoApi.SysInfoGetSysConfig('signinforfreecontent').then(data => {
			dispatch({
				type: SYSINFO_SIGNINFORFREECONTENT,
				payload: data.value === 'true',
			});
			return data.value === 'true';
		}).catch(error => {
			throw(error);
		});
	};
};

export const sysInfoGetThumbnailPath = () => {
	return dispatch => {
		return sysinfoApi.SysInfoGetSysConfig('thumbnailPath').then(data => {
			dispatch({
				type: SYSINFO_THUMBNAILPATH,
				payload: data.value,
			});
			return data.value;
		}).catch(error => {
			throw(error);
		});
	};
};

export const sysInfoGetHboHelpMail = () => {
	return dispatch => {
		return sysinfoApi.SysInfoGetSysConfig('hboHelpMail').then(data => {
			dispatch({
				type: SYSINFO_HBOHELPMAIL,
				payload: data.value,
			});
			return data.value;
		}).catch(error => {
			throw(error);
		});
	};
};

export const sysInfoGetConviva = () => {
  return dispatch => {
		return sysinfoApi.SysInfoGetSysConfig('convivaweb').then(data => {
			dispatch({
				type: SYSINFO_CONVIVA,
				payload: data.value === 'true',
			});
			return  data.value === 'true';
		}).catch(error => {
			dispatch({
				type: SYSINFO_CONVIVA,
				payload: false,
			});
			return false;
		});
	};
}

export const sysInfoGetImpression = () => {
	return dispatch => {
		  return sysinfoApi.SysInfoGetSysConfig('impressionweb').then(data => {
			  dispatch({
				  type: SYSINFO_IMPRESSION,
				  payload: data.value === 'true',
			  });
			  localStorage.setItem('impressionweb', data.value === 'true');
			  return  data.value === 'true';
		  }).catch(error => {
			  dispatch({
				  type: SYSINFO_IMPRESSION,
				  payload: false,
			  });
			  localStorage.setItem('impressionweb', false);
			  return false;
		  });
	  };
  }
  
export const sysInfoGetRecommendation = () => {
	return dispatch => {
		return sysinfoApi.SysInfoGetSysConfig('recommendation_web').then(data => {
			dispatch({
				type: SYSINFO_RECOMMENDATION,
				payload: data.value === 'true',
			});
			localStorage.setItem('recommendation_web', data.value === 'true');
			return  data.value === 'true';
		}).catch(error => {
			dispatch({
				type: SYSINFO_RECOMMENDATION,
				payload: false,
			});
			localStorage.setItem('recommendation_web', false);
			return false;
		});
	};
}

export const sysInfoGetDeleteAcc = () => {
	return dispatch => {
		return sysinfoApi.SysInfoGetSysConfig('deleteAcc_web').then(data => {
			dispatch({
				type: SYSINFO_DELETEACC,
				payload: data.value === 'true',
			});
			localStorage.setItem('deleteAcc_web', data.value === 'true');
			return  data.value === 'true';
		}).catch(error => {
			// default is true
			dispatch({
				type: SYSINFO_DELETEACC,
				payload: true,
			});
			localStorage.setItem('recommendation_web', true);
			return true;
		});
	};
}

export const sysInfoGetDisableChangePlan = () => {
	return (dispatch, getState) => {
		const sysInfoAllConfig = getState().sysinfo.sysInfoAllConfig;
		return sysInfoAllConfig ? sysInfoAllConfig['disableChangePlan_web'] === 'true' : false;
	}
}

export const sysInfoGetDisableCCUpdate = () => {
	return (dispatch, getState) => {
		const sysInfoAllConfig = getState().sysinfo.sysInfoAllConfig;
		return sysInfoAllConfig ? sysInfoAllConfig['disableCCUpdate_web'] === 'true' : false;
	}
}

export const sysInfoGetDisableCancelSubscription= () => {
	return (dispatch, getState) => {
		const sysInfoAllConfig = getState().sysinfo.sysInfoAllConfig;
		return sysInfoAllConfig ? sysInfoAllConfig['disableCancelSubscription_web'] === 'true' : false;
	}
}