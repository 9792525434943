import continuewatchApi from './api/continuewatchApi';

export const CONTINUEWATCH_LIST = 'continuewatch/LIST';

const initialState = {
	continuewatchList: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case CONTINUEWATCH_LIST:
			return {
				...state,
				continuewatchList: action.payload
			};

		default:
			return state;

	}
};

export const continuewatchGetList = (country, token, sortBy, sortOrder) => {
	return dispatch => {
		if(localStorage.resume){
			let resume = JSON.parse(localStorage.resume);
      var resumeTime = resume.time;
			var hour = Math.floor(resumeTime / 3600);
	    var mins = Math.floor((resumeTime - hour * 3600) / 60);
	    var seconds = Math.floor(resumeTime % 60);
	    hour = hour.toString().padStart(2, '0');
	    mins = mins.toString().padStart(2, '0');
	    seconds = seconds.toString().padStart(2, '0');
			var continueInfo = {
				sessionToken: localStorage.session_token,
				channelPartnerID: localStorage.channel_partner_id,
				multiProfileId: "0",
				contentId: resume.contentId,
				resumeTime: `${hour}:${mins}:${seconds}`,
				contentType: resume.contentType
			};
			if(resume.tvseriesId){
				continueInfo.tvseriesId = resume.tvseriesId;
			}
			return continuewatchApi.ContinuewatchInsert(continueInfo).then(data => {

        if (resume.isHide) {
          
          let continuewatchId = [];
          continuewatchId.push(data._id);
          return continuewatchApi.ContinuewatchRemove({
            sessionToken: localStorage.session_token,
            multiProfileId: "0",
            continuewatchId: continuewatchId,
          }).then(res => {
            localStorage.removeItem('resume');
            return continuewatchApi.ContinuewatchGetList(country, token, sortBy, sortOrder).then(data => {
              dispatch({
                type: CONTINUEWATCH_LIST,
                payload: data,
              });					
              return data;
            }).catch(error => {
              throw(error);
            });
          }).catch(error => {
            throw(error);
          });

        } else {

          localStorage.removeItem('resume');
          return continuewatchApi.ContinuewatchGetList(country, token, sortBy, sortOrder).then(data => {
            dispatch({
              type: CONTINUEWATCH_LIST,
              payload: data,
            });					
            return data;
          }).catch(error => {
            throw(error);
          });
          
        }
			}).catch(error => {
				return continuewatchApi.ContinuewatchGetList(country, token, sortBy, sortOrder).then(data => {
					dispatch({
						type: CONTINUEWATCH_LIST,
						payload: data,
					});
					return data;
				}).catch(error => {
					throw(error);
				});
			});

		}else{
			return continuewatchApi.ContinuewatchGetList(country, token, sortBy, sortOrder).then(data => {
				dispatch({
					type: CONTINUEWATCH_LIST,
					payload: data,
				});
				return data;
			}).catch(error => {
				throw(error);
			});
		}
	};
};

export const continuewatchDeleteAll = (payload) => {
	return dispatch => {
		return continuewatchApi.ContinuewatchDeleteAll(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const continuewatchInsert = (payload) => {
	return dispatch => {
		return continuewatchApi.ContinuewatchInsert(payload).then(data => {
			localStorage.removeItem('resume');
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const continuewatchRemove = (payload) => {
	return dispatch => {
		return continuewatchApi.ContinuewatchRemove(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
