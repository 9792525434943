import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import {
	translationGetList,
} from '../../modules/translation';

class Translate extends Component {
	render() {
    const { translationList, id, appLanguage, replace, optional } = this.props;
    let fontFamily = (appLanguage==='tha') ? 'prompt' : '';
    let result = (optional)?"":id;
		let thisLang = appLanguage.replace('-','_');
		if(translationList){
			let res = translationList.results.filter((e)=>{
				let rep = false;
				let list = e.web.split(',');
				if(e.web.replace('.json','').trim() === id){
					rep = true;
				}
				for(var i in list){
					if(list[i].replace('.json','').trim() === id){
						rep = true;
					}
				}
				return rep;
      });
      if (res[0] && (thisLang in res[0])) {
        result = res[0][thisLang].replace(/\n/g, '<br/>');
        if (replace && Object.keys(replace).length > 0) {
          for (const [key, value] of Object.entries(replace)) {
            result = result.replace('{'+key+'}', value);
          }
        }
      }
			return (<span className={`${fontFamily}`} dangerouslySetInnerHTML={{__html: result}}></span>);
		}else{
			return "";
		}
	}
};


const mapStateToProps = state => ({
  appLanguage: state.app.appLanguage,
  translationList: state.translation.translationList
});

const mapDispatchToProps = dispatch =>
bindActionCreators(
	{
  translationGetList,
	},
	dispatch
);

export const getTranslation = (translationList, id, appLanguage) => {
  let result = "";

  let thisLang = appLanguage.replace('-','_');
  if(translationList){
    let res = translationList.results.filter((e)=>{
      let rep = false;
      let list = e.web.split(',');
      if(e.web.replace('.json','').trim() === id){
        rep = true;
      }
      for(var i in list){
        if(list[i].replace('.json','').trim() === id){
          rep = true;
        }
      }
      return rep;
    });
    if (res[0] && res[0][thisLang]) {
      result = res[0][thisLang].replace(/\n/g, '<br/>');
      result = result.replace('<!>', '');
    }
    return result;
  } else {
    return '';
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Translate));
