import axios from 'axios';
import config from '../../config';
import apiInstance from './index';

const liveInstance = apiInstance;

class LiveApi {
	static LiveGetList(country) {
		return liveInstance.get(`/live?territory=${country}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static LiveGetChannel(country, channel) {
		return liveInstance.get(`/live?territory=${country}&channelId=${channel}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static LiveCheckConcurrent(country, channel, token, operator) {
		return liveInstance.get(`/asset/verifyLivePlaybackurl?territory=${country}&channelId=${channel}&sessionToken=${token}&channelPartnerID=${localStorage.channel_partner_id}&operatorId=${operator}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static LivePlaybackGetURLList(country, id, token, operatorId) {
		// return playbackInstance.get(`/asset/playbackurl?territory=${country}&contentId=${id}&sessionToken=${token}&operatorId=${operatorId}&lang=${localStorage.default_lang}`)
		return liveInstance.get(`/asset/liveplaybackurl?territory=${country}&channelId=${id}&sessionToken=${token}&channelPartnerID=${localStorage.channel_partner_id}&operatorId=${operatorId}&lang=${localStorage.default_lang}`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

}

export default LiveApi;
