import gtmModule from '../components/analytics/gtmModule';

export const LANDING_CONTENT = 'impression/CONTENT';
export const SCROLL_END_BUFFER = 500; // milliseconds

const initialState = {
	impressionContent: {},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case LANDING_CONTENT:
			return {
				...state,
				impressionContent: action.payload
			};

		default:
			return state;

	}
};

export const impressionAddRecord = (payload) => {
	return (dispatch, getState) => {
        if (localStorage.getItem('impressionweb') !== 'true') {
            return;
        }
        const {
            collectionTitle, 
            contentId, 
            contentTitle, 
            index, 
            location, 
            recordId, // store in web, for checking whether sent or not
        } = payload;    
        if(dispatch(isImpressionCanSend(recordId))) {
            let impressionState = getState().impression.impressionContent;
            impressionState[recordId] = true;
            dispatch({
                type: LANDING_CONTENT,
                payload: impressionState,
            });
            const event_category = 'HBOGO-Impression';
			const event_action = 'Content_Impression';
			const event_label = {
				label: `${contentTitle} | ${contentId}`,
                loc: location,
                collection_name: collectionTitle,
                title_index: index,
			};
            console.log('send', recordId, event_label);
			gtmModule(event_category, event_action, event_label);  
        }
        return payload;
	};
};

export const isImpressionCanSend = (recordId) => {
    return (dispatch, getState) => {
        const impressionState = getState().impression.impressionContent;
        return !(recordId in impressionState);
    };
}

export const isScrolledIntoView = (elemRef) => {
    if (localStorage.getItem('impressionweb') !== 'true') return false;
    if (elemRef.getBoundingClientRect().height === 0) return false;
    var docViewTop = 0;
    var docViewBottom = docViewTop + window.innerHeight;

    var elemTop = elemRef.getBoundingClientRect().top;
    var elemBottom = elemTop + elemRef.getBoundingClientRect().height;
    var elemMiddle = (elemTop + elemBottom) / 2; 

    return ((elemMiddle <= docViewBottom) && (elemMiddle >= docViewTop));
}

export const isScrolledIntoContainerHorizontal = (elemRef, containerRef) => {
    if (localStorage.getItem('impressionweb') !== 'true') return false;
    var docViewTop = containerRef.getBoundingClientRect().left;
    var docViewBottom = docViewTop + containerRef.getBoundingClientRect().width;

    var elemTop = elemRef.getBoundingClientRect().left;
    var elemBottom = elemTop + elemRef.getBoundingClientRect().width;
    var elemMiddle = (elemTop + elemBottom) / 2; 
    return ((elemMiddle <= docViewBottom) && (elemMiddle >= docViewTop));
}