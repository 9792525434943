import recommendationApi from './api/recommendationApi';

export const RECOMMENDATION_LIST = 'recommendation/LIST';

const initialState = {
	recommendationList: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case RECOMMENDATION_LIST:
			return {
				...state,
				recommendationList: action.payload
			};

		default:
			return state;

	}
};

export const recommendationGetList = (sessionToken, channelPartnerID, territory, itemsCount) => {
	return dispatch => {
		return recommendationApi.RecommendationGetList(sessionToken, channelPartnerID, territory, itemsCount).then(data => {
			dispatch({
				type: RECOMMENDATION_LIST,
				payload: data,
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};
